import React                               from 'react';


export default function Error() {
  
  return (
    <div style = {{display: 'flex', alignItems: 'center', alignContent: 'center', flexDirection: 'column'}}>
            <h2>
                UNABLE TO RETRIEVE ROLE, PLEASE CONTACT SUPPORT
            </h2>
    </div>
  )
}