import featureToggle from '../../../featureToggle.json';
import ScoringSVG from '../../../images/scoring graphs.svg';
import ClearIcon from '@mui/icons-material/Clear';
import FAQsSVG from '../../../images/FAQs.svg';
import Dev from '../../dev/Dev';
import { Flex } from '@aws-amplify/ui-react';
import { useState, useEffect, useContext } from 'react';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { FiltersContext } from '../../../components/Navigation/Routes';
import { Modal, Button } from '@mui/material';
import { IconButton } from "@mui/material";
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import { Box } from '@mui/system';
import usersService from '../../../axios/usersService';


export const ServiceAndInventory = ({ page }) => {
  const context = useContext(FiltersContext);
  const {
    channel,
    viewType,
    impersonatorInfo,
    setSldWeekly
  } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [openFAQ, setOpenFAQ] = useState(false);
  const handleOpenFAQ = () => setOpenFAQ(true);
  const handleCloseFAQ = () => setOpenFAQ(false);
  const [openSC, setOpenSC] = useState(false);
  const handleOpenSC = () => setOpenSC(true);
  const handleCloseSC = () => setOpenSC(false);
  const [faqSource, setFAQSource] = useState(null);
  const [scSource, setSCSource] = useState(null);


  //Handles FAQ popup
  useEffect(() => {
    const fetchFAQEmbed = async () => {
      const roleInformation = Object.values(impersonatorInfo).length === 0 ? {} : impersonatorInfo;
      roleInformation.view = viewType;
      const srcFAQ = await usersService.getEmbeddingUrl(
        "metrics-faq",
        [],
        roleInformation,
        Object.values(impersonatorInfo).length > 0
      );
      setFAQSource(srcFAQ);
    }

    if (page === "sld") {
      fetchFAQEmbed()
    }
  }, [openFAQ, impersonatorInfo, viewType, page]);

  //Handles Scoring Key popup
  useEffect(() => {
    const fetchSCEmbed = async () => {
      const roleInformation = Object.values(impersonatorInfo).length === 0 ? {} : impersonatorInfo;
      roleInformation.view = viewType;

      const srcSC = await usersService.getEmbeddingUrl(
        "score-card",
        [],
        roleInformation,
        Object.values(impersonatorInfo).length > 0
      );
      setSCSource(srcSC);
    }

    if (page === "sld") {
      fetchSCEmbed()
    }

  }, [openSC, impersonatorInfo, viewType, page]);


  useEffect(() => {
    setSldWeekly(false);

    return () => setSldWeekly(false);
  }, [page, setSldWeekly]);


  useIdleTimer(channel);


  if (featureToggle[environment][page]) {
    return (
      <Flex direction={"column"} width={"100%"} height={"100%"} overflow={"hidden"} paddingTop={"12px"}>
        <Flex direction={"row"} style={{ marginInlineStart: "20px" }}>
          <Button
            onClick={() => setSldWeekly(false)} className='information-buttons'
          >
            SUMMARY
          </Button>
          <Button
            onClick={() => setSldWeekly(true)} className='information-buttons'
          >
            Detailed Reports
          </Button>
          <Flex direction={'row'} justifyContent={'flex-end'} width={'80%'}>
            <IconButton
              onClick={handleOpenSC}
              className='custom-icon-button'
            >
              <img src={ScoringSVG} alt="Scoring Graphs" />
            </IconButton>
            <Modal
              className='embed-popup'
              open={openSC}
              onClose={handleCloseSC}
            >
              <Box className='embed-popup-box'>
                <div className='popup-close'>
                  <IconButton onClick={(handleCloseSC)}>
                    <ClearIcon color={"error"} />
                  </IconButton>
                </div>
                <iframe
                  id={"SC"}
                  src={scSource}
                  title={"Scoring Key"}
                  className="popup-iframe"
                />
              </Box>
            </Modal>

            <IconButton
              onClick={handleOpenFAQ}
              className='custom-icon-button'
            >
              <img src={FAQsSVG} alt="Vendor Metrics FAQ's" />
            </IconButton>
            <Modal
              className='embed-popup'
              open={openFAQ}
              onClose={handleCloseFAQ}
            >
              <Box className='embed-popup-box'>
                <div className='popup-close'>
                  <IconButton onClick={(handleCloseFAQ)}>
                    <ClearIcon color={"error"} />
                  </IconButton>
                </div>
                <iframe
                  id={"FAQ"}
                  src={faqSource}
                  title={"FAQ"}
                  className="popup-iframe"
                />
              </Box>
            </Modal>
          </Flex>
        </Flex>

        <SigmaEmbed page={page} />
      </Flex>
    )
  } else {
    return <Dev />
  }
}