import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import '../../App.css'


export function FaqAccordion(props) {
    const { question, answer, id } = props;

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className='faq-accordion'
            key={id}
        >
            <AccordionSummary
                expandIcon={expanded ? <ExpandCircleDownIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <h3 className="accordion-text"
                >
                    {question}
                </h3>
            </AccordionSummary>
            <AccordionDetails>
                <p className="accordion-text"
                >
                    {answer}
                </p>
            </AccordionDetails>
        </Accordion>
    )
}