import React, { useEffect, useState } from 'react';
import { Hub, Auth } from 'aws-amplify'; // Import your authentication library
import { Login } from './login/Login';
import { useNavigate } from "react-router";
import App from './App';


const withCustomAuthentication = (WrappedComponent, LoginScreen) => {
  return (props) => {
    const [authenticated, setAuthenticated] = useState(false);

    // Add a subscription to listen for authentication events
    useEffect(() => {
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
            setAuthenticated(true);
            break;
          case 'signOut':
            setAuthenticated(false);
            break;
          default:
            break;
        }
      });
    }, [])

    useEffect(() => {
      const checkAuthentication = async () => {
        const user = await checkAuthStatus();
        if (user) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      };

      checkAuthentication();
    }, [authenticated]);

    if (authenticated) {
      return <WrappedComponent {...props} />;
    } else {
      return <LoginScreen />;
    }
  };
};

const AppWithAuthentication = withCustomAuthentication(App, Login);

const Authenticator = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppWithAuthentication />
  );
};


const checkAuthStatus = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    return null;
  }
};



export default Authenticator;