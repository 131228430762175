import { 
  getBrokerAreas,
  getBrokerManagerData,
  getVendorsForBrokers,
  getBrokerAvailableAreas,
  getSelectedBrokerAreas,
  getBrokerInformation,
  getSubscribedVendors, 
  getSubscribedVendorRoles, 
  getVendorMerchCategories, 
  getUnsubscribedVendors, 
  getVendorGroupMerchCategories,
  getSubscribedVendorsAtrributes,
  getImpersonatorVendors,
  getChildrenVendors,
  getBrokerList,
  getBrokerVendors
} from "../graphql/queries";
import { 
  insertChildVendor, 
  insertParentVendor, 
  insertVendorCategory, 
  updateChildVendors, 
  updateParentVendor, 
  updateVendorCategories,
  insertBroker,
  insertBrokerAreas,
  updateBroker,
  updateBrokerAreas,
  manageVendorStatus
} from "../graphql/mutations";
import { axiosRequest } from "../utils/axiosRequest";
import { Auth } from "aws-amplify";


const vendorsService = {
  getHeaders: async () => {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': jwt
    }
  },

  issueRequest: async (body) => {
    const headers = await vendorsService.getHeaders();
    const method = "post"
    const options = { headers, body, method: method };

    return await axiosRequest(options);
  },

  getVendors: async (jwt) => {
    const body = {
      query: getSubscribedVendors,
      variables: {}
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getSubscribedVendors?.data;

    return data
  },

  getChildVendors: async(vendorGroupNumber) => {
    const body = {
      query: getChildrenVendors,
      variables: {
        vendorGroupNumber: vendorGroupNumber
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getChildrenVendors;

    return data
  },

  getVendorsAttributes: async (jwt, vendorGroupNumber=null) => {
    const body = {
      query: getSubscribedVendorsAtrributes,
      variables: {
        vendorGroupNumber: vendorGroupNumber
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getSubscribedVendors?.data;

    return data;
  },

  getSubscribedVendorAdminAttributes: async () => {
    const body = {
      query: getImpersonatorVendors,
      variables: {}
    };
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getImpersonatorVendors?.data;
    
    return data;
  },

  getVendorRoles: async () => {
    const body = {
      query: getSubscribedVendorRoles
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getSubscribedVendorRoles?.data;

    return data
  },

  getBrokerAreas: async(childVendors) => {
    const body = {
      query: getBrokerAreas,
      variables : {childVendors: childVendors}
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getBrokerAreas?.data;

    return data
  },

  getSelectedBrokerAreas: async(vendorGroupNumber, brokerGroupName) => {
    const body = {
      query: getSelectedBrokerAreas,
      variables : {
        vendorGroupNumber: vendorGroupNumber,
        brokerGroupName: brokerGroupName
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getSelectedBrokerAreas?.data;

    return data
  },

  getBrokerForManagePage: async() => {
    const body = {
      query: getBrokerManagerData,
      variables: {}
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getBrokerManagerList?.data;

    return data
  },

  getVendorsForBrokers: async(is_broker, broker) => {
    const body = {
      query: getVendorsForBrokers,
      variables: {
        is_broker: is_broker,
        broker: broker
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getVendorsForBrokers?.data;

    return data;
  },

  getBrokerAvailableAreas: async(vendor_group_name, vendor_group_number) => {
    const body = {
      query: getBrokerAvailableAreas,
      variables: {
        brokerGroupName: vendor_group_name,
        vendorGroupNumber: vendor_group_number
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getBrokerAvailableAreas?.data;

    return data;
  },

  getBrokerInformation: async() => {
    const body = {
      query: getBrokerInformation,
      variables: {
        vendorGroupNumber: null
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getSelectedBrokerAreas?.data;

    return data;
  },

  insertBroker: async(broker_group_name) => {
    const body = {
      query: insertBroker,
      variables: {
        broker_group_name: broker_group_name
      }
    };
    
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.insertBroker;

    return data;
  },

  getBrokerList: async() => {
    const body = {
      query: getBrokerList,
      variables: {}
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getBrokerList?.data;
    
    return data;
  },

  getBrokerVendors: async(is_broker, broker) => {
    const body = {
      query: getBrokerVendors,
      variables: {
        is_broker: is_broker,
        broker: broker
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getBrokerVendors?.data;
    
    return data;
  },

  insertBrokerAreas: async(area_names, vendor_group_name) => {
    const body = {
      query: insertBrokerAreas,
      variables : {
        area_names: JSON.stringify(area_names),
        vendor_group_name: vendor_group_name
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.insertBrokerAreas;

    return data
  },

  updateBroker: async(broker_group_number, broker_group_name) => {
    const body = {
      query: updateBroker,
      variables : {
        broker_group_name: broker_group_name,
        broker_group_number: broker_group_number
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.updateBroker;

    return data
  },

  updateBrokerAreas: async(area_names, broker_group_name) => {
    const body = {
      query: updateBrokerAreas,
      variables : {
        area_names: JSON.stringify(area_names),
        broker_group_name: broker_group_name
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.updateBrokerAreas;

    return data
  },

  createChildVendor: async (jwt, selectedChildVendors, vendorGroupName) => {
    const body = {
      query: insertChildVendor,
      variables : {
        childVendors: JSON.stringify(selectedChildVendors),
        vendorGroupName: vendorGroupName
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.insertChildVendor;

    return data
  },

  updateChildVendors: async(childVendors, vendorGroupName, vendorGroupNumber) => {
    const body = {
      query: updateChildVendors,
      variables : {
        childVendors: JSON.stringify(childVendors),
        vendorGroupName: vendorGroupName,
        vendorGroupNumber: parseInt(vendorGroupNumber)
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.updateChildVendors;

    return data
  },

  createParentVendor: async (jwt, vendorGroupName, primaryContactName, primaryContactEmail, primaryContactPhone, secondaryContactName, secondaryContactEmail, secondaryContactPhone, categoryDirector, categoryManager, subscriptionId, subscriptionName, isBroker, isCustomer) => {
    const body = {
      query: insertParentVendor,
      variables : {
        vendorRoleName: null,
        vendorGroupName: vendorGroupName,
        primaryContactName: primaryContactName,
        primaryContactEmail: primaryContactEmail,
        primaryContactPhone: primaryContactPhone,
        secondaryContactName: secondaryContactName,
        secondaryContactEmail: secondaryContactEmail,
        secondaryContactPhone: secondaryContactPhone,
        categoryDirector: categoryDirector,
        categoryManager: categoryManager,
        subscriptionId: subscriptionId,
        subscriptionName: subscriptionName,
        isBroker: isBroker,
        isCustomer: isCustomer
      }
    };
  
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.insertParentVendor;
  
    return data
  },

  updateParentVendor: async(vendorGroupNumber, vendorGroupName, primaryContactName, primaryContactEmail, primaryContactPhoneNumber, secondaryContactName, secondaryContactEmail, secondaryContactPhoneNumber, categoryDirector, categoryManager, subscriptionId, subscriptionName, status, isCustomer) => {
    const body = {
      query: updateParentVendor,
      variables : {
        vendorGroupNumber: vendorGroupNumber,
        vendorGroupName: vendorGroupName,
        primaryContactName: primaryContactName,
        primaryContactEmail: primaryContactEmail,
        primaryContactPhoneNumber: primaryContactPhoneNumber,
        secondaryContactName: secondaryContactName,
        secondaryContactEmail: secondaryContactEmail,
        secondaryContactPhoneNumber: secondaryContactPhoneNumber,
        categoryDirector: categoryDirector,
        categoryManager: categoryManager,
        subscriptionId: subscriptionId,
        subscriptionName: subscriptionName,
        status: status,
        isCustomer: isCustomer
      }
    };
  
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.updateParentVendor;
  
    return data
  },

  fetchVendorCategories: async (jwt, vendorIds) => {
    const body  = {
      query: getVendorMerchCategories,
      variables: {
          vendorIds: vendorIds
      }
    }
  
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getVendorMerchCategories;
  
    return data
  },

  fetchSelectedVendorCategories: async(vendorGroupNumber) => {
    const body = {
      query: getVendorGroupMerchCategories,
      variables: {
        vendorGroupNumber: vendorGroupNumber
      }
    };
    
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getVendorGroupMerchCategories;

    return data
  },

  fetchUnsubscribedVendors: async (jwt, isBroker, vendorName) => {
    const body = {
      query: getUnsubscribedVendors,
      variables: {
        vendorName: vendorName,
        isBroker: isBroker
      }
    };

    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.getUnsubscribedVendors;

    return data
  },

  createVendorCategory: async (jwt, vendorGroupName, childrenCategoryNames) => {
    const body = {
      query   : insertVendorCategory,
      variables : {
        vendorGroupName: vendorGroupName,
        childrenCategoryNames: JSON.stringify(childrenCategoryNames)
      }
    }
  
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.insertVendorCategory;

    return data
  },

  updateVendorCategories: async (vendorGroupName, childrenCategoryNames, vendorGroupNumber) => {
    const body = {
      query   : updateVendorCategories,
      variables : {
        vendorGroupName: vendorGroupName,
        childrenCategoryNames: JSON.stringify(childrenCategoryNames),
        vendorGroupNumber: vendorGroupNumber
      }
    }
  
    const res = await vendorsService.issueRequest(body);
    const data = res.data?.data?.updateVendorCategories;

    return data
  },

  manageVendorStatus: async (vendorGroupNumber, action) => {

    const method = 'post';
    const body = {
      query: manageVendorStatus,
      variables: {
        vendorGroupNumber: vendorGroupNumber,
        action: action
      }
    };
    const result = await vendorsService.issueRequest(body, method);

    return result;
  },

}

export default vendorsService;