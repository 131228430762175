import React, {useState} from 'react';
import { TrainingVideos } from './TrainingVideos';
import { TrainingDocuments } from './TrainingDocuments';
import { TrainingSummary } from './TrainingSummary';
import { TrainingResources } from './TrainingResources';

export function Training(){
    const [page,setPage] = useState(2);

    const togglePage = (index) =>{
        setPage(index);
    }


    return(
    <div className= 'training-page'>
    <div className= 'training-options'>
        {/* HIDING SUMMARY & RESOURCES FOR NOW */}
        {/* <div className= {page === 1 ?'active-tab':'training-tab'} onClick={() => togglePage(1)}>SUMMARY</div> */}
        <div className= {page === 2 ?'active-tab':'training-tab'} onClick={() => togglePage(2)}>TRAINING DOCUMENTS</div>
        <div className= {page === 3 ?'active-tab':'training-tab'} onClick={() => togglePage(3)}>TRAINING VIDEOS</div>
        {/* <div className= {page === 4 ?'active-tab':'training-tab'} onClick={() => togglePage(4)}>RESOURCES</div> */}
    </div>
    <div className = 'current-training'>
        <div className={page === 1 ?'active-content':'content'}>
            <TrainingSummary page={page}/>
        </div>
        <div className={page === 2 ?'active-content':'content'}>
            <TrainingDocuments page={page}/>
        </div>
        <div className={page === 3 ?'active-content':'content'}>
            <TrainingVideos page={page}/>
        </div>
        <div className={page === 4 ?'active-content':'content'}>
            <TrainingResources page={page}/>
        </div>

    </div>
    </div>
    )
}