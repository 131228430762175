import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import { Flex } from '@aws-amplify/ui-react';
import { useState, useContext } from 'react';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { CustomToggleGroup } from '../../../components/CustomToggleGroup';
import { FiltersContext } from '../../../components/Navigation/Routes';
import featureToggle from '../../../featureToggle.json';
import DateOptions from '../../../layouts/DateOptions';
import Dev from '../../dev/Dev';


export const SupplierPerformance = ({ page }) => {
  const context = useContext(FiltersContext);
  const {
    channel,
    switchOn,
    setSwitchOn
  } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [switchDisabled, setSwitchDisabled] = useState(true);
  const [checked, setChecked] = useState(true);

  
  useIdleTimer(channel);


  if (featureToggle[environment][page]) {
    return (
      <Flex direction={"row"} height={"100%"}>
        <Flex direction={"column"} width={"100%"} height={"100%"} overflow={"hidden"} paddingTop={"12px"}>
          {page === "yearly" || page === "sld" || page === "home" ? null :
            <>
              <Flex direction={"row"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 12px 12px 12px"}>
                <CustomToggleGroup />
              </Flex>
            </>
          }

          <DateOptions
            page={page}
            checked={checked} setChecked={setChecked}
            switchOn={switchOn} setSwitchOn={setSwitchOn}
            switchDisabled={switchDisabled} setSwitchDisabled={setSwitchDisabled}
          />

          <SigmaEmbed page={page} />
        </Flex>
      </Flex>
    )
  } else {
    return <Dev />
  }
}