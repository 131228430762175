import { useContext } from 'react';
import { FiltersContext } from '../Navigation/Routes';
import FilterControls from './FilterControls.json';
import { Card, Flex } from '@aws-amplify/ui-react';
import { formatFilters, removeTags } from '../../utils/helpers';
import { handleSharedFiltersSave } from './handlers';
const FilterConfirm = ({ onClose, setConfig, controlData }) => {
  const context = useContext(FiltersContext);
  const {
    viewType,
    filters,
    savedFiltersName,
    filtersNameInput, setFiltersNameInput
  } = context;


  const detaggedFilters = removeTags(viewType, filters, '');
  const displayedFilters = formatFilters(detaggedFilters);
  const view = viewType === 'basic'? 'Portfolio' : 'Category';


  const getControlValues = (page, control) => {

    if (controlData[page].data && controlData[page].data[control]) {
      return decodeURIComponent(controlData[page].data[control]).split(',');
    }
    return null;
  };

  const ControlCard = ({ page, view, control, data, type }) => {
    
    const controlValues = type ==='Filters' ? data : getControlValues(page, data[page][view][control]);
    const controlKey = type ==='Filters' ?  control : `${page}_${control}`
    return (
      <Card key={`${controlKey}_key`} padding={"0"} borderRadius={"10px"}>
        <Flex direction={"row"}>
          <div className="filter-summary-left-div"></div>
          <div className="filter-summary-cards">
            <p className="filter-summary-card-headers">
              <strong>{control}</strong>
            </p>
            {controlValues ? (
              <ul className="filter-summary-card-content">
                {controlValues.slice(0, 5).map((dispVal) => (
                  <li key={`${dispVal}_key`}>{dispVal}</li>
                ))}
              </ul>
            ) : (
              <ul className="filter-summary-card-content">
                {/* temporary fix for default values. --default values should be included in next iteration */}
                <li key={`${controlKey}_key`}>{`${(page === "QuickFacts" && (control === "Dimensions" || control === "Calculation Type")) ? "No selections" :"Default selection"}`}</li>
              </ul>
            )}
            <p className="filter-summary-card-content">
              {controlValues && controlValues.length > 5
                ? `... ${controlValues.length - 5} more values selected`
                : ""}
            </p>
          </div>
        </Flex>
      </Card>
    );
  };
  
  const ControlList = ({ page, view, data, type }) => {
    if (type === "Filters"){
      return (
        <div className="control-filter-list">
          {data.map(([dispKey, dispValArr]) => (
            <ControlCard key={`${dispKey}`} page={"Filters"} view={view} control={dispKey} data={dispValArr} type = {type}/>
          ))}
        </div>
      );
    }
    else{
      return (
        <div className="control-filter-list">
          {Object.keys(data[page][view]).map((control) => (
            <ControlCard key={`${page}_${control}`} page={page} view={view} control={control} data={data} type = {type}/>
          ))}
        </div>
      );
    }

  };
  
  const renderData = (data, view, type) => {
    if (type === "Filters"){
      return data[0] ? (
        <div key={"Filters"}>
          <h2>{"Filters"}</h2>
          <ControlList page={"Filters"} view={view} data={data} type = {type}/>
        </div>
      ) : (<div key={"Filters"}><h2>{"Filters"}</h2><p>No filters selected</p></div>);
    }
    else{
      return Object.keys(data).map((page) => (
        <div key={page}>
          <h2>{page}</h2>
          <ControlList page={page} view={view} data={data} type = {type} />
        </div>
      ));
    }

  };
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>You are saving the following {view} filters: </h2>
        <div className= "filters-popup">
          <div className='filters-container'>
            {renderData(FilterControls, view, "Controls")}
            {renderData(displayedFilters, view, "Filters")}
          </div>

        </div>
        <div className='filters-button-container'>
          <button className='filters-button-cancel' onClick={onClose}>CANCEL</button>
          <button className='filters-button-confirm' onClick={() =>{
            handleSharedFiltersSave(controlData, FilterControls, view, filters, viewType, filtersNameInput, setFiltersNameInput, savedFiltersName, setConfig);
            onClose()}}>CONFIRM</button>
        </div>
      </div>
    </div>
  );
};

export default FilterConfirm;