import axios from 'axios';

const sigmaService = {
    //use axios to get the token to authenticate with the API, then pass the token in the header in future requests
    deactivateUser: async(email) => {
        try {
        const token_reponse = await axios.request({
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                url: process.env.REACT_APP_SIGMA_API_TOKEN_AUTH_URL,
                method: "post",
                baseURL: process.env.REACT_APP_SIGMA_API_BASE_URL,
                data: "grant_type=client_credentials", 
                auth: {
                    username: process.env.REACT_APP_SHARED_TABLE_SIGMA_API_KEY,
                    password: process.env.REACT_APP_SHARED_TABLE_SIGMA_API_SECRET
                }
            }
        )
        console.log('Authenticated')
  
        const token = token_reponse.data.access_token
  
        // Search for the member ID associated with the email
        const memberId_response = await axios.request({
                url: `https://aws-api.sigmacomputing.com/v2/members?search=${email}`,
                method: "get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": `Bearer ${token}`
                }
            })
  
        const memberId = memberId_response.data[0].memberId
        // Deactivate user based on the memberId
        axios.request({
            url: `https://aws-api.sigmacomputing.com/v2/members/${memberId}`,
            method: "delete",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": `Bearer ${token}`
            }
        }).then(() => {
          console.log('User deactivated in Sigma.')
        })
    } 
    catch(error){
        console.log(error)
    }
  }}
  
  export default sigmaService;