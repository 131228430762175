import { IconButton } from "@mui/material"


export const DeleteIconButton = ({ disabled, className, onClick }) => {
    return (
        <IconButton className={className} onClick={onClick} disabled={disabled} >
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.43096 2.43097C5.49348 2.36846 5.57826 2.33334 5.66667 2.33334H8.33333C8.42174 2.33334 8.50652 2.36846 8.56904 2.43097C8.63155 2.49349 8.66667 2.57827 8.66667 2.66668V3.00001H5.33333V2.66668C5.33333 2.57827 5.36845 2.49349 5.43096 2.43097ZM3.33333 3.00001V2.66668C3.33333 2.04784 3.57917 1.45435 4.01675 1.01676C4.45434 0.579176 5.04783 0.333344 5.66667 0.333344H8.33333C8.95217 0.333344 9.54566 0.579176 9.98325 1.01676C10.4208 1.45435 10.6667 2.04784 10.6667 2.66668V3.00001H11.6667H13C13.5523 3.00001 14 3.44773 14 4.00001C14 4.55229 13.5523 5.00001 13 5.00001H12.6667V13.3333C12.6667 13.9522 12.4208 14.5457 11.9832 14.9833C11.5457 15.4208 10.9522 15.6667 10.3333 15.6667H3.66667C3.04783 15.6667 2.45434 15.4208 2.01675 14.9833C1.57917 14.5457 1.33333 13.9522 1.33333 13.3333V5.00001H1C0.447715 5.00001 0 4.55229 0 4.00001C0 3.44773 0.447715 3.00001 1 3.00001H2.33333H3.33333ZM3.33333 5.00001V13.3333C3.33333 13.4217 3.36845 13.5065 3.43096 13.569C3.49348 13.6316 3.57826 13.6667 3.66667 13.6667H10.3333C10.4217 13.6667 10.5065 13.6316 10.569 13.569C10.6315 13.5065 10.6667 13.4217 10.6667 13.3333V5.00001H9.66667H4.33333H3.33333ZM5.66667 6.33334C6.21895 6.33334 6.66667 6.78106 6.66667 7.33334V11.3333C6.66667 11.8856 6.21895 12.3333 5.66667 12.3333C5.11438 12.3333 4.66667 11.8856 4.66667 11.3333V7.33334C4.66667 6.78106 5.11438 6.33334 5.66667 6.33334ZM9.33333 7.33334C9.33333 6.78106 8.88562 6.33334 8.33333 6.33334C7.78105 6.33334 7.33333 6.78106 7.33333 7.33334V11.3333C7.33333 11.8856 7.78105 12.3333 8.33333 12.3333C8.88562 12.3333 9.33333 11.8856 9.33333 11.3333V7.33334Z" fill="white" />
            </svg>
        </IconButton>
    )
}