import React from 'react';
import { Drawer } from "@mui/material";
import { List } from "@mui/material";
import { MainListItems, SecondaryListItems } from "../HeaderMenu";
import { Box } from "@mui/system";
import '../../App.css';
import './UserDrawer.css'


export function UserDrawer(props) {
  const list = (anchor) => (
    <Box
      sx={{ 
        width: anchor === 'bottom' ? 'auto' : 220, 
        height: anchor === 'left' ? "100%": null 
      }}
      role="presentation"
      onClick={props.drawerClose}
      onKeyDown={props.drawerClose}
    >
      <List className='leftside-list' >
        { 
          anchor === 'left' ?
          <MainListItems/> :
          <SecondaryListItems/>
        }
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor='left'
      position='fixed'
      variant="permanent"
      sx={{
        width: 220,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 220,
          paddingTop: "10px",
          height: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundColor: "#FFFFFF",
          boxSizing: 'border-box',
          borderRight: "1px solid rgba(0, 0, 0, 0.1)"
        },
      }}
      className='drawer-border'
    >
      <div className='logo-container'>
        <img
          alt="shortened-logo"
          className="app-bar-logo"
          src="/USF-ST-logo.png"
        />
      </div>
      {list('left')}
      {list('bottom')}
    </Drawer>
  );
}