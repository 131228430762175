/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  Switch
} from 'antd';
import CMS from '../../../cms/cms.json';
import { Auth } from 'aws-amplify';
import { AmplifyAlertBanner } from '../../../components/AmplifyAlertBanner';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import vendorsService from '../../../axios/vendorsService';
import usersService from '../../../axios/usersService';
import Loading from '../../../components/Loading';
import BlockIcon from '@mui/icons-material/Block';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import sigmaService from '../../../axios/sigmaService';

export function EditUser() {

  const [firstName, setFirstName] = useState("");
  const [vendor, setVendor] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [active, setActive] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [brokerVendorList, setBrokerVendorList] = useState([]);
  const [brokerIsChecked, setBrokerIsChecked] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState();
  const [showConfirmationModal, setConfirmationModal] = useState(false)
  const [config, setConfig] = useState({ variation: "", msg: "" });
  const location = useLocation();
  const navigate = useNavigate();
  const jwt = useRef();
  const [form] = Form.useForm();

  const handleModal = (value) => {
    setConfirmationModal(value);
  }

  useEffect(() => {
    Auth.currentSession().then(async (session) => {
      jwt.current = session.getIdToken().getJwtToken();
      const [SubVendorListRes, usersListRes] = await Promise.all([vendorsService.getBrokerVendors(false, 'NON-BROKER'), usersService.getUsers(jwt.current)]);
      setVendorList(SubVendorListRes);
      const foundEntry = (usersListRes.data.find((item) => item.mail === location.state.email));
      if (foundEntry) {
        setSelectedBroker(foundEntry.associatedBroker);
        setFirstName(foundEntry.firstName);
        setLastName(foundEntry.lastName);
        setEmail(foundEntry.mail);
        setNewEmail(foundEntry.mail);
        setVendor(foundEntry.vendorName);
        setPhone(foundEntry.phone);
        setActive(foundEntry.active);
        setBrokerIsChecked(foundEntry.isBroker);
        setLoaded(true);
      }
    }).catch((err) => {
      console.error("ERROR: ", err);
    });
  }, [location.state.email]);

  useEffect(() => {
    async function getVendorBrokers(selectedBroker) {
      const vendorBrokerRes = await vendorsService.getBrokerVendors(true, selectedBroker);
      setBrokerVendorList(vendorBrokerRes);
    }
    getVendorBrokers(selectedBroker);
  }, [selectedBroker]);

  const onSubmit = async (values) => {
    setComponentDisabled(true);
    const vendorGroupNumber = brokerIsChecked ? brokerVendorList.find(item => item.vendor_group_name === vendor).vendor_group_number : vendorList.find(item => item.vendor_group_name === vendor).vendor_group_number;
    const associatedBroker = values['selectedBroker'] ? values['selectedBroker'] : 'NON-BROKER';
    console.log("DEBUG associatedBroker: ", associatedBroker);

    await usersService.editUserAtt(email, values["newEmail"], vendorGroupNumber, values["firstName"], values["lastName"], values["phone"], associatedBroker).then((res) => {
      res ? console.log("edit User response", res) : console.error("please verify your entries.");
      if (res === true) {
        const state = { variation: "success", msg: "User information successfully updated" };
        navigate('/manage-user', { state: state });
      }
    }).catch((err) => {
      console.error("Encountered error when editing user: ", err);
      const state = { variation: "error", msg: "User information failed to update" };
      navigate('/manage-user', { state: state });
    });
    setComponentDisabled(false);
  }

  const onDeactivateUser = async () => {
    setComponentDisabled(true);
    await usersService.manageUserStatus(email, 'deactivate').then((res) => {
      if (res?.data?.data?.manageUserStatus) {
        // deactivate the user in Sigma
        sigmaService.deactivateUser(email)
        navigate('/manage-user');
      }}).catch((err) => {
      console.error("ERROR editing user: ", err);
      setConfig({ variation: "error", msg: "Error deactivating user, please contact admin" });
    });

    setComponentDisabled(false);
    handleModal(false);
  }

  const onActivateUser = async () => {
    setComponentDisabled(true);
    await usersService.manageUserStatus(email, 'activate').then((res) => {
      if (res?.data?.data?.manageUserStatus) {
        //TODO display success message  
        //form.resetFields(); // rests the form to the edited values 
        navigate('/manage-user');
      }
    }).catch((err) => {
      console.error("ERROR editing user: ", err);
      setConfig({ variation: "error", msg: "Error activating user, please contact admin" });
    });
    setComponentDisabled(false);
    handleModal(false);
  }


  return (!loaded ? <Loading /> :
    <div id='edit-user'>
      <div className='alert-banner'>
        <AmplifyAlertBanner
          isDismissible={true}
          config={config}
        />
      </div>
      <h2 className='page-title'>
        {CMS.pages.find(o => o.name === "editUser")?.title}
      </h2>
      <Form form={form} initialValues={{ firstName, lastName, newEmail, phone, vendor, selectedBroker }} onFinish={onSubmit} labelCol={{ span: 40 }} wrapperCol={{ span: 30 }} layout="vertical">
        <div className='attribute-box'>
          <Form.Item label="FIRST NAME:" name="firstName" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="LAST NAME:" name="lastName" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="EMAIL:" name="newEmail" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label="PHONE:" name="phone" >
            <Input />
          </Form.Item>

          <Form.Item label="BROKER?:" name="broker" >
            <Switch
              className='period-switch-div'
              disabled={true}
              checked={brokerIsChecked}
              size="large"
            />
          </Form.Item>

          {
            brokerIsChecked ?
              <Form.Item name="selectedBroker" label="ASSOCIATED BROKER:" required rules={[{ required: true }]}>
                <Select
                  mode='single'
                  initialvalues
                  className='select-vendors'
                  popupClassName='select-vendors-popup'
                  onChange={setSelectedBroker}
                  options={[{"broker_group_name": selectedBroker}]}
                  fieldNames={{ label: 'broker_group_name', value: 'broker_group_name' }} // set the label and value for the select options
                  filterOption={(inputValue, option) =>
                    option.broker_group_name // search by vendorgroupname
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  } // lowercase search for matching between the input and the options
                  showSearch // need it to be able to type in the search bar
                  disabled
                />
              </Form.Item>
              :
              null
          }

          <Form.Item label={brokerIsChecked ? "DEFAULT VENDOR" : "VENDOR:"} name="vendor" required rules={[{ required: true }]}>
            <Select // adding the same vendor-search functionality for editUser since it was added to the createUser page
              mode="single"
              initialvalues={vendor}
              onChange={setVendor}
              options={brokerIsChecked && selectedBroker && brokerVendorList ? brokerVendorList : vendorList}
              fieldNames={{ label: 'vendor_group_name', value: 'vendor_group_name' }}
              className='select-vendors'
              popupClassName='select-vendors-popup'
              filterOption={(inputValue, option) => {
                return option.vendor_group_name // search by vendorgroupname
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }} // lowercase search for matching between the input and the options
              showSearch // need it to be able to type in the search bar
              />
          </Form.Item>

        </div>
        <div className='edit-user-buttons'>
          <Form.Item>

            {
              active ?
                <Button className="deactivate-button" onClick={() => handleModal(true)}>
                  <div className='button-items'>
                    {CMS.pages.find(o => o.name === "deactivate")?.title}
                    <BlockIcon className='deactivate-icon' />
                  </div>
                </Button> :
                <Button className="activate-button" onClick={() => handleModal(true)}>
                  <div className='button-items'>
                    {CMS.pages.find(o => o.name === "activate")?.title}
                    <ManageAccountsIcon className='activate-icon' />
                  </div>
                </Button>
            }

            <Button className="cancel-button" onClick={() => { navigate('/manage-user') }}>
              <div className='button-items'>
                {CMS.pages.find(o => o.name === "cancel")?.title}
                <CloseRoundedIcon className='cancel-icon' />
              </div>
            </Button>

            <Button type='primary' className="save-button" htmlType="submit" disabled={componentDisabled}>
              <div className='button-items'>
                {CMS.pages.find(o => o.name === "save")?.title}
                <SaveOutlinedIcon className='save-icon' />
              </div>
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        open={showConfirmationModal}
        onOk={() => active ? onDeactivateUser() : onActivateUser()}
        onCancel={() => handleModal(false)}
      >
        <p>Do you want to {active ? 'deactivate' : 'active'} this user?</p>
      </Modal>
    </div>
  );
};