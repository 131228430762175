/* eslint-disable react-hooks/exhaustive-deps */

import { Alert } from "@aws-amplify/ui-react"
import { useEffect, useState, useCallback } from "react"


// {*} config - Object<variation:String,msg:String>
export const AmplifyAlertBanner = ({ isDismissible, config }) => {
    const [displayAmplifyAlert, setDisplayAmplifyAlert] = useState(false);
    const [amplifyAlertConfig, setAmplifyAlertConfig] = useState(config);

    const triggerAlertDisplay = useCallback((bool) => {
        setDisplayAmplifyAlert(bool);
    }, []);

    const updateAmplifyAlertConfig = useCallback((config) => {
        triggerAlertDisplay(true);
        setAmplifyAlertConfig(() => { 
            return { 
                variation: config.variation,
                msg: config.msg 
            } 
        });
    }, [triggerAlertDisplay])


    useEffect(() => {
        if (config.variation && config.msg) {
            updateAmplifyAlertConfig(config);
        }
        
    }, [JSON.stringify(config), updateAmplifyAlertConfig])

    useEffect(() => {
        if (displayAmplifyAlert) {
            let timer = setTimeout(() => triggerAlertDisplay(false), 5000)

            return (() => clearTimeout(timer))
        }
    }, [displayAmplifyAlert, triggerAlertDisplay])


    return (
        displayAmplifyAlert ?
        <Alert 
            className="amplify-alert" 
            isDismissible={isDismissible} 
            onDismiss={() => triggerAlertDisplay(false)} 
            variation={amplifyAlertConfig.variation}
        >
            {amplifyAlertConfig.msg}
        </Alert>
        :
        null
    )
}