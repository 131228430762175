import featureToggle from '../../../featureToggle.json';
import DateOptions from '../../../layouts/DateOptions';
import usersService from '../../../axios/usersService';
import Dev from '../../dev/Dev';
import { Flex } from '@aws-amplify/ui-react';
import { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useIdleTimer } from '../../../utils/IdleTimer';
import { FiltersContext } from '../../../components/Navigation/Routes';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import { Spin } from 'antd';

export const Home = ({ page }) => {
  const context = useContext(FiltersContext);
  const {
    channel,
    switchOn,
    setSwitchOn,
    impersonatorInfo
  } = context;

  const [homePepper, setHomePepper] = useState(null);

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [checked, setChecked] = useState(true);
  const [switchDisabled, setSwitchDisabled] = useState(true);

  useEffect(() => {
    async function fetchPeppers() {
      const peppers = await usersService.getPeppers(null, null, impersonatorInfo ? impersonatorInfo.sfRole : "");

      if (peppers && !peppers.errors) {
        setHomePepper(peppers);
      }
    };

    if (page === "home") {
      fetchPeppers();
    }
  }, [JSON.stringify(impersonatorInfo)]);

  useIdleTimer(channel);

  if (featureToggle[environment][page]) {
    return (
      <Flex direction={"row"} height={"100%"}>
        <Flex direction={"column"} width={"100%"} height={"100%"} overflow={"hidden"} paddingTop={"12px"}>

          {page === "home" ?
            <Flex direction={'row'} justifyContent={"space-between"} alignItems={'flex-end'} marginLeft={"12px"} paddingBottom={"5px"}>
              <Flex direction={'column'}>
                <Flex >
                  <p className='acc-titles' >CURRENT WEEK SERVICE PERFORMANCE</p>
                </Flex>
                <Flex direction={'row'} alignItems={'center'} width={"110%"} justifyContent={"space-between"}>
                  <NavLink to={'service-and-inventory'}>
                    {
                      homePepper?.pepperFR ?
                        <img
                          alt="pepper-home"
                          className='pepper-home'
                          src={`https://${homePepper?.pepperFR}`}
                        /> :
                        <Spin className='custom-spin' size='small' />
                    }
                  </NavLink>
                  <div className='bold-font'>
                    Fill Rate
                  </div>

                  <NavLink to={'/service-and-inventory'}>
                    {
                      homePepper?.pepperOT ?
                        <img
                          alt="&#xU+1F336" // unicode for pepper emoji
                          className='pepper-home'
                          src={`https://${homePepper?.pepperOT}`}
                        /> :
                        <Spin className='custom-spin' size='small' />
                    }
                  </NavLink>
                  <div className='bold-font'>
                    On Time Delivery
                  </div>
                </Flex>
              </Flex>

              <Flex>
                <DateOptions
                  page={page}
                  checked={checked} setChecked={setChecked}
                  switchOn={switchOn} setSwitchOn={setSwitchOn}
                  switchDisabled={switchDisabled} setSwitchDisabled={setSwitchDisabled}
                />
              </Flex>
            </Flex>
            : null}
          <SigmaEmbed page={page} />
        </Flex>
      </Flex>
    );
  } else {
    return <Dev />;
  }
};