import { sortObject } from '../utils/helpers';
import { useState, useEffect } from 'react';
import vendorsService from "../axios/vendorsService";


export const useChildrenVendors = (vendorGroupNumber) => {
    const [childrenVendors, setChildrenVendors] = useState(null);
    const [childrenVendorsLoaded, setChildrenVendorsLoaded] = useState(false);
    
    useEffect(() => {
        const fetchChildrenVendors = async() => {
            setChildrenVendorsLoaded(false);
            const childrenVendorsRes = await vendorsService.getChildVendors(vendorGroupNumber);
            const { data } = childrenVendorsRes;
            let vendorData = [];
            if (data) {
                vendorData = data.map((vendor) => {
                    return {
                        label: vendor.vendorName,
                        id: vendor.vendorNumber
                    }
                })
                vendorData = vendorData.sort(sortObject);
            }
            setChildrenVendors(vendorData);
            setChildrenVendorsLoaded(true);
        }

        fetchChildrenVendors();

    }, [vendorGroupNumber])

    return { childrenVendors, childrenVendorsLoaded }
}