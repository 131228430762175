import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { UserDrawer } from './UserDrawer/UserDrawer';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Hub, Auth } from 'aws-amplify';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CMS from '../cms/cms.json';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AlertImpersonate from './AlertImpersonate';
import { FiltersContext } from '../components/Navigation/Routes';
import { Flex } from '@aws-amplify/ui-react';
import { logOut } from '../utils/helpers';


export default function HeaderAppBar() {
  const context = useContext(FiltersContext);
  const {
    channel,
    impersonatorInfo, setImpersonatorInfo,
    setViewType,
    handleResetFilters
  } = context;

  const location = useLocation();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBroker, setIsBroker] = useState(false);
  const [hover, setHover] = useState();
  const [alertImpersonate, setAlertImpersonate] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [open, setOpen] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(() => {
    return Object.keys(impersonatorInfo).length === 0 ? false : true;
  });

  const toggleAlertImpersonate = () => {
    setAlertImpersonate(!alertImpersonate);

    handleMenu(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleCloseImpersonatorMenu = () => {
    setSelectedVendor(null);
    setAlertImpersonate(false);
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  // event handlers
  const handleImpersonating = (impersonatorInfo) => {
    setIsImpersonating(true);
    setImpersonatorInfo({...impersonatorInfo});
    setHover(false);
    handleCloseImpersonatorMenu();
    handleResetFilters();
  };

  // event handlers
  const handleLeaveImpersonating = () => {
    setIsImpersonating(false);
    setImpersonatorInfo({});
    if (isBroker) {
      setViewType('basic');
    } else {
      setViewType('insights');
    }
    handleCloseImpersonatorMenu();
    handleResetFilters();
  };

  const handleTitleChange = (title) => {
    if (title === "/") {
      setPageTitle("HOME");
    } else if (title.includes("quick-facts")) {
      setPageTitle("QUICK FACTS");
    } else if (title.includes("supplier-performance")) {
      setPageTitle("SUPPLIER PERFORMANCE");
    } else if (title.includes("void-report")) {
      setPageTitle("VOID REPORT");
    } else if (title.includes("weekly-trend")) {
      setPageTitle("WEEKLY TREND");
    } else if (title.includes("4-year-trend")) {
      setPageTitle("4 YEAR TREND");
    } else if (title.includes("service-and-inventory")) {
      setPageTitle("SERVICE & INVENTORY");
    } else if (title.includes("produce")) {
      setPageTitle("S&I PRODUCE");
    } else if (title.includes("purchasing")) {
      setPageTitle("PURCHASING");
    } else if (title.includes("sales")) {
      setPageTitle("SALES");
    } else if (title.includes("faqs")) {
      setPageTitle("FAQS");
    } else if(title.includes("training")) {
      setPageTitle("TRAINING");
    } else {
      setPageTitle("");
    }

  };


  useEffect(() => {
    handleTitleChange(location.pathname);
  }, [location.pathname]);


  useEffect(() => {
    Hub.dispatch("impersonate", {
      event: 'ImpersonationInvocation',
      message: 'Impersonation Flow Invoked'
    });

  }, [JSON.stringify(impersonatorInfo)]);


  useEffect(() => {
    Auth.currentSession().then(async (session) => {
      const userRef = session.getIdToken().payload;
      // console.log('userRef', userRef);
      const isInternalUser = userRef["cognito:groups"] && userRef["cognito:groups"].includes("Internal");

      if (isInternalUser) {
        setIsAdmin(true);
      } else if (userRef.identities && userRef.identities[0].providerName === 'AzureAD') {
        // internal user first time logged in, dont know if this will be hit in the future 
        setIsAdmin(true);
      } else if (userRef["cognito:groups"].includes("Broker")) {
        setIsBroker(true);
      }

      setUserName(userRef.name ? userRef.name : userRef.email.split('.')[0]);//note the profile scope fixes the need for this email parsing...cognito has to be updated first
    })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  return (
    <div className='header-appbar-div'>
      <Box
        className='appbar-container'
        sx={{
          display: "flex",
          paddingTop: "8px",
          flexGrow: 1
        }}
      >

        <AppBar
          className="appbar"
          style={{
            display: "flex",
            alignContent: 'center',
            paddingTop: "0px",
            height: "40px",
            backgroundColor: "#1f1f1f",
            boxShadow: "none"
          }}
          position="fixed"
          sx={{ width: { sm: `calc(100% - 220px)` }, ml: { sm: "220px" } }}
          enableColorOnDark
        >
          <UserDrawer />

          <Toolbar
            className="toolbar-comp"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "0px",
              minHeight: "100%"
            }}
          >

            <Flex direction={"row"}>
              {
                location.pathname.includes('quick-facts') ||
                  location.pathname.includes('void-report') ||
                  location.pathname.includes('weekly-trend')
                  ?
                  <div className='appbar-div-grey'>
                    {`${CMS.pages.find(o => o.name === 'dataQuery').title} \xa0 | \xa0`}
                  </div>
                  : null
              }
              <div className='appbar-div-white'>
                {pageTitle}
              </div>
            </Flex>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />


            <div>
              {isImpersonating ?
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>

                  {hover ?
                    <Button
                      onClick={handleLeaveImpersonating}
                      id="impersonate"
                      startIcon={<CloseOutlinedIcon style={{ fill: "red" }} />}>
                      <div style={{ color: "red" }}>
                        <p className='glowred'>
                          Close this view
                        </p>
                      </div>
                    </Button>
                    :
                    <Button
                      style={{ width: "100%" }}
                      id="impersonate"
                      startIcon={<VisibilityOutlinedIcon style={{ fill: "#76AA35" }} />}>
                      <div>
                        <p className='glowgreen'>
                          Viewing as {impersonatorInfo.name}
                        </p>
                        
                      </div>
                      {/* change to impersonatorInfo.name */}
                    </Button>}
                </div>
                :
                false
              }
            </div>

            <div className='appbar-menu-div'>
              <Button
                id="icon"
                onClick={handleMenu}
                className='appbar-button-menu'
              >
                <div className='appbar-impr-name-div'>
                  {"Hi, " + userName}
                </div>
              </Button>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfile}>{CMS.pages.find(o => o.name === "profile")?.title}</MenuItem>
                {isAdmin || isBroker ? <MenuItem onClick={toggleAlertImpersonate}>{CMS.pages.find(o => o.name === "impersonateVendor")?.title}</MenuItem> : null}
                <MenuItem onClick={() => logOut(channel)}>{CMS.pages.find(o => o.name === "signOut")?.title}</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>

      <AlertImpersonate
        alertImpersonate={alertImpersonate} setAlertImpersonate={setAlertImpersonate}
        setIsImpersonating={setIsImpersonating}
        selectedVendor={selectedVendor} setSelectedVendor={setSelectedVendor}
        handleImpersonating={handleImpersonating}
        handleCloseImpersonatorMenu={handleCloseImpersonatorMenu} 
        handleOpenModal={handleOpenModal}
        open={open}
      />
    </div>
  );
};