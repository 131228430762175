import React, { useState, useEffect, useRef } from 'react';
import vendorsService from '../../../../axios/vendorsService';
import usersService from '../../../../axios/usersService';
import { AmplifyAlertBanner } from '../../../../components/AmplifyAlertBanner';
import { Auth } from 'aws-amplify';
import {
  Form,
  Input,
  Button,
  Select,
  Switch
} from 'antd';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CMS from '../../../../cms/cms.json';
import { useNavigate } from 'react-router-dom';

export function CreateUser() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [brokerIsChecked, setBrokerIsChecked] = useState(false);

  const [vendorList, setVendorList] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const [brokerVendorList, setBrokerVendorList] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [vendorDisabled, setVendorDisabled] = useState(false);
  const [config, setConfig] = useState({ variation: "", msg: "" });

  const jwt = useRef();

  const handleBrokerChange = () => {
    setBrokerIsChecked((val) => !val);
  };

  useEffect(() => {
    Auth.currentSession().then(async (session) => {
      jwt.current = session.getIdToken().getJwtToken();
      const subVendorsRes = await vendorsService.getBrokerVendors(false, 'NON-BROKER');

      // TODO add error state to the component and render error message if fetch fails
      if (!subVendorsRes) {
        setConfig({ variation: "error", msg: "Error to retrieve Broker Vendors." });
      }

      setVendorList(subVendorsRes);
    }).catch((err) => {
      console.error("ERROR: ", err);
    });
  }, []);

  useEffect(() => {
    async function getVendorBrokers(selectedBroker) {
      const vendorBrokerRes = await vendorsService.getBrokerVendors(true, selectedBroker);
      setBrokerVendorList(vendorBrokerRes);
      setVendorDisabled(false);
    }
    getVendorBrokers(selectedBroker);
  }, [selectedBroker]);

  useEffect(() => {
    form.resetFields(["Vendor", "Associated Broker"]);
    if (brokerIsChecked) {
      async function getBrokers() {
        const brokerRes = await vendorsService.getBrokerList();
        setBrokerList(brokerRes);
      }
      getBrokers();
      setVendorDisabled(true);
    } else {
      setVendorDisabled(false);
      setSelectedBroker("");
    }
  }, [brokerIsChecked]);

  const onSubmit = async (values) => {
    // prevent multiple submissions 
    setComponentDisabled(true);
    const associatedBroker = values['Associated Broker'] ? values['Associated Broker'] : 'NON-BROKER';

    const createUserRes = await usersService.createVendorUser(values['Email'], values['First Name'], values['Last Name'], values['Vendor'], values['Phone'], brokerIsChecked, associatedBroker);

    if (createUserRes) {
      setConfig({ variation: "success", msg: "New User successfully created." });
      form.resetFields();
      setBrokerIsChecked(false);
    } else {
      //TODO display error message 
      console.error('An error occured in form submission');
    }
    setComponentDisabled(false);
  };

  const onCancel = (event) => {
    navigate('/manage-user');
  };

  return (
    <div id='create-user' >

      <h2 className='page-title'>
        {CMS.pages.find(o => o.name === "createUser")?.title}
      </h2>

      <AmplifyAlertBanner
        isDismissible={true}
        config={config}
      />

      <Form form={form} onFinish={onSubmit} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}  >
        <div className='attribute-box'>
          <Form.Item name='First Name' label="First Name:" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name='Last Name' label="Last Name:" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="Email" label="Email:" required rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="Phone" label="Phone:">
            <Input />
          </Form.Item>

          <Form.Item label="Broker?:" name="isBroker" >
            <Switch
              className='period-switch-div'
              onChange={handleBrokerChange}
              checked={brokerIsChecked}
              value={brokerIsChecked}
              size="large"
            />
          </Form.Item>

          {
            brokerIsChecked ?
              <Form.Item name="Associated Broker" label="Associated Broker:" required rules={[{ required: true }]}>
                <Select
                  className='select-vendors'
                  popupClassName='select-vendors-popup'
                  onChange={setSelectedBroker}
                  options={brokerList} // set the options for the select
                  fieldNames={{ label: 'broker_group_name', value: 'broker_group_name' }} // set the label and value for the select options
                  filterOption={(inputValue, option) =>
                    option.broker_group_name // search by vendorgroupname
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  } // lowercase search for matching between the input and the options
                  showSearch // need it to be able to type in the search bar
                />
              </Form.Item>
              :
              null
          }

          <Form.Item name="Vendor" label={brokerIsChecked ? "Default Vendor" : "Vendor:"} required rules={[{ required: true }]}>
            <Select
              className='select-vendors'
              popupClassName='select-vendors-popup'
              options={brokerIsChecked && selectedBroker && brokerVendorList ? brokerVendorList : vendorList} // set the options for the select
              fieldNames={{ label: 'vendor_group_name', value: 'vendor_group_number' }} // set the label and value for the select options
              filterOption={(inputValue, option) => {
                return option.vendor_group_name // search by vendorgroupname
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
              }} // lowercase search for matching between the input and the options
              showSearch // need it to be able to type in the search bar
              disabled={vendorDisabled}
            />
          </Form.Item>
        </div>
        <div className='create-user-buttons'>
          <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
            <Button className="cancel-button" onClick={onCancel}>
              <div className='button-items'>
                {CMS.pages.find(o => o.name === "cancel")?.title}
                <CloseRoundedIcon className='cancel-icon' />
              </div>
            </Button>

            <Button type='primary' className="save-button" htmlType="submit" disabled={componentDisabled}>
              <div className='button-items'>
                {CMS.pages.find(o => o.name === "save")?.title}
                <SaveOutlinedIcon className='save-icon' />
              </div>
            </Button>

          </Form.Item>
        </div>
      </Form>
    </div>
  );
};