import { Flex } from "@aws-amplify/ui-react";
import CircleIcon from "@mui/icons-material/Circle";


// columns for vendor management
export const manageVendorColumns = [
    {
        title: "Vendor",
        align: "center",
        dataIndex: "vendorGroupName",
        sorter: (a, b) => a?.vendorGroupName?.length - b?.vendorGroupName?.length,
        defaultSortOrder: 'descend'
    },
    {
        title: "Vendor Group Number",
        align: "center",
        dataIndex: "vendorGroupNumber",
    },
    {
        title: "Subscription",
        align: "center",
        dataIndex: "subscriptionName",
    },
    {
        title: "Status",
        align: "center",
        dataIndex: "status",
        render: (_, row) =>
            <Flex alignItems={"center"} justifyContent={"center"}>
                <CircleIcon style={{ fill: row.status === 'active' ? 'green' : 'red', fontSize: '10px' }} />
                <div>{row.status}</div>
            </Flex>,
    },
    {
        title: "Created",
        align: "center",
        dataIndex: "createdAt"
    }
];