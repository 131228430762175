import { useIdleTimer } from '../../../utils/IdleTimer';
import { CustomToggleGroup } from '../../../components/CustomToggleGroup';
import { FiltersContext } from '../../../components/Navigation/Routes';
import { FilterSummary } from '../../../layouts/FilterSummary/FilterSummary';
import { Flex } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import { useState, useContext } from 'react';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import CustomAccordion from '../../../layouts/CustomAccordion';
import DateOptions from '../../../layouts/DateOptions';
import featureToggle from '../../../featureToggle.json';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Dev from '../../dev/Dev';
import './QuickFacts.css';

export const QuickFacts = ({ page }) => {
  const context = useContext(FiltersContext);
  const {
    channel,
    handleResetFilters,
    switchOn,
    setSwitchOn
  } = context;

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const [switchDisabled, setSwitchDisabled] = useState(true);
  const [checked, setChecked] = useState(true);


  useIdleTimer(channel);


  if (featureToggle[environment][page]) {
    return (
      <Flex direction={"row"} height={"100%"}>
        <Flex direction={"column"} width={"100%"} height={"100%"} overflow={"hidden"} paddingTop={"12px"}>
          <>
            <Flex direction={"column"}>
              <Flex direction={"row"} alignItems={"center"} justifyContent={"space-between"} margin={"0px 12px 12px 12px"}>
                <CustomToggleGroup />
                <Flex direction={"row"}>
                  <CustomAccordion />
                  <div className='refresh-icon-container'>
                    <Button
                      className='refresh-button'
                      variant="contained"
                      onClick={() => handleResetFilters(page)}
                      disableElevation
                    >
                      <div className='icon-container'>
                        <CachedOutlinedIcon className='cached-outlined-icon' />
                      </div>
                    </Button>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </>
          <DateOptions
            page={page}
            checked={checked} setChecked={setChecked}
            switchOn={switchOn} setSwitchOn={setSwitchOn}
            switchDisabled={switchDisabled} setSwitchDisabled={setSwitchDisabled}
          />
          <SigmaEmbed page={page} />
        </Flex>
        <FilterSummary />
      </Flex>
    );
  } else {
    <Dev />;
  }
};