import { useState, useEffect } from 'react';
import vendorsService from "../axios/vendorsService";
import featureToggle from '../featureToggle.json'


// refactor note: look into useInformation hook with 
// a switch on userGroup to conditionally execute a function
// (i.e. fetchBrokerInformation | fetchVendorInformation | fetchUserInformation)
export const useVendorsForBrokers = (action) => {
    const [vendorsForBrokers, setVendorsForBrokers] = useState(null);
    const [vendorsForBrokersLoaded, setVendorsForBrokersLoaded] = useState(false);

    useEffect(() => {
        const fetchBrokerInformation = async () => {
            setVendorsForBrokersLoaded(false);
            setVendorsForBrokers(null);
            const data = await vendorsService.getVendorsForBrokers();
            setVendorsForBrokers(data);
            setVendorsForBrokersLoaded(true);
        }
        
        const brokerIsEnabled = featureToggle[process.env.REACT_APP_ENVIRONMENT]["broker"];
        if (brokerIsEnabled) {
            fetchBrokerInformation()
        }

    }, [action]);

    return { vendorsForBrokers, vendorsForBrokersLoaded };
}
   