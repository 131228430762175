import vendorsService from "../../../../axios/vendorsService";
import { getNewCategories } from "../../../../utils/helpers";
import { options } from "../subscriptionOptions";


// EDIT VENDOR ON SUBMIT HANDLER AND FORM INITIALIZATION
export const updateTables = async (values, categoriesList, vendorGroupNumber, initialSubscriptionName, status, selectedVendors, selectedBrokers) => {
    const clientMsg = {};
    const newSubscriptionName = options.find((item) => item.value === values['vendorLevel']).label;
    const resArr = [];

    console.log(values);

    const updateParentRes = await vendorsService.updateParentVendor(
        vendorGroupNumber,
        values['vendorGroupName'],
        values['primaryContactName'],
        values['primaryContactEmail'],
        values['primaryContactPhoneNumber'],
        values['secondaryContactName'],
        values['secondaryContactEmail'],
        values['secondaryContactPhoneNumber'],
        values['categoryDirector'],
        values['categoryManager'],
        values['vendorLevel'],
        newSubscriptionName,
        values['status'],
        values['isCustomer'] ? `${values['isCustomer']}` : 'false'
    );
    resArr.push(updateParentRes);

    const formattedChildVendorInformation = selectedVendors
    .map((child) => {
        return {
            vendorName: child.label,
            vendorId: child.id
        }
    });
    
    const updateChildRes = await vendorsService.updateChildVendors(
        formattedChildVendorInformation,
        values['vendorGroupName'],
        vendorGroupNumber
    );
    resArr.push(updateChildRes);

    if (initialSubscriptionName !== "Category" && newSubscriptionName === "Category") {
        const { value } = values;
        const newCategories = getNewCategories(value, categoriesList);
        const formattedCategories = newCategories.map((value) => value);
        const createVendorCategoryRes = await vendorsService.createVendorCategory(
            null,
            values['vendorGroupName'],
            formattedCategories
        );
        resArr.push(createVendorCategoryRes);
    } else if (initialSubscriptionName === "Category") {
        const { value } = values;
        const newCategories = getNewCategories(value, categoriesList);
        const formattedCategories = newCategories.map((value) => value);
        const updateVendorCategoriesRes = await vendorsService.updateVendorCategories(
            values['vendorGroupName'],
            formattedCategories,
            vendorGroupNumber
        );
        resArr.push(updateVendorCategoriesRes);
    }

    if (values['isBroker']) {
        let brokerInfo = selectedBrokers
        .map((obj) => {
            return {
                area_name: obj.label,
                area_id: obj.id
            };
        });
        // brokerInfo = brokerInfo.filter((broker) => values.Area.includes(broker.area_id));

        const updateBrokerAreasRes = await vendorsService.updateBrokerAreas(
            vendorGroupNumber,
            brokerInfo,
            values['vendorGroupName']
        );
        resArr.push(updateBrokerAreasRes);
    }

    const initialVendorStatus = status;
    const selectedVendorStatus = values["status"] ? "active" : "inactive";

    if (initialVendorStatus !== selectedVendorStatus) {
        const action = values["status"] === true ? "activate" : "deactivate";
        const statusRes = await vendorsService.manageVendorStatus(vendorGroupNumber, action)
                .catch((err) => {
                    console.error("Error deactivating vendor: ", err);
                    return false
                });
        resArr.push(statusRes);
    }

    const allRes = await Promise.all(resArr);

    if (allRes.every((value) => value)) {
        clientMsg.status = true;
        clientMsg.msg = "Successfully published vendor update";
    } else {
        clientMsg.status = false;
        clientMsg.msg = "Sorry, something went wrong. Please contact USF support";
    }

    return clientMsg;
}