import React from 'react';


export default function Loading() {
  
  return (
    <div className='center-loading'>
            <span className="loader">
            </span>
            <h1 className='loading-title'>
                Loading...
            </h1>
    </div>
  )
}