import { useState, useEffect } from 'react';
import vendorsService from "../axios/vendorsService";


export const useSelectedVendorGroupCategories = (vendorGroupNumber) => {
    const [selectedVendorGroupCategories, setSelectedVendorGroupCategories] = useState(null);
    const [selectedVendorGroupCategoriesLoaded, setSelectedVendorGroupCategoriesLoaded] = useState(false);

    useEffect(() => {
        setSelectedVendorGroupCategoriesLoaded(true);

        const fetchSelectedVendorCategories = async() => {
            setSelectedVendorGroupCategoriesLoaded(false);
            const selectedVendorCategoriesResponse = await vendorsService.fetchSelectedVendorCategories(vendorGroupNumber);
            const { data } = selectedVendorCategoriesResponse;
            const merchCategories = data ? data.map((item) => item.merchCategoryName) : [];
            setSelectedVendorGroupCategories(merchCategories);
            setSelectedVendorGroupCategoriesLoaded(true);
        }

        fetchSelectedVendorCategories();

    }, [vendorGroupNumber])

    return { selectedVendorGroupCategories, selectedVendorGroupCategoriesLoaded }
}