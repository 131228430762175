/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_s1xTbTMv8",
    "aws_user_pools_web_client_id": "4v94bm95kag1edpfpq1n6ov5gh",
    "oauth": {
        "domain": "shared-table-nonprod-qa.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:3000/home,https://qa-temp.d1hogjtovck671.amplifyapp.com/,https://qa-temp.d1hogjtovck671.amplifyapp.com/home,https://qa.d1hogjtovck671.amplifyapp.com/,https://qa.d1hogjtovck671.amplifyapp.com/home,https://sharedtableqa.usfoods.com/,https://www.sharedtableqa.usfoods.com/",
        "redirectSignOut": "http://localhost:3000,http://localhost:3000/signout,https://qa-temp.d1hogjtovck671.amplifyapp.com/,https://qa-temp.d1hogjtovck671.amplifyapp.com/signout,https://qa.d1hogjtovck671.amplifyapp.com/,https://qa.d1hogjtovck671.amplifyapp.com/signout,https://sharedtableqa.usfoods.com/,https://sharedtableqa.usfoods.com/signout,https://www.sharedtableqa.usfoods.com/,https://www.sharedtableqa.usfoods.com/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "FAMILY_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
