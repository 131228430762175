export const options = [
    {
        label: 'Viewer',
        value: '1'
    },
    {
        label: 'Portfolio',
        value: '2'
    },
    {
        label: 'Category',
        value: '3'
    }
];