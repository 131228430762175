import { TreeSelect, Spin } from 'antd';
import { useCallback, useEffect } from 'react';
import { formatCategoriesList } from '../../utils/helpers';


export const TreeSelector = ({ treeData, setTreeData, value, setValue, categoriesList, vendorLevel, onChange, selectedChildVendors, loaded, disabled }) => {
  const { SHOW_PARENT } = TreeSelect;
  const categoriesProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    placement: 'bottomLeft',
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Select a Merch Category'
  };

  const changeTreeData = useCallback(() => {
    const formattedCategoriesList = formatCategoriesList(categoriesList, vendorLevel);
    setTreeData(formattedCategoriesList);
  }, [categoriesList, setTreeData, vendorLevel])


  useEffect(() => {
    if (!treeData || !vendorLevel?.target?.value === '3') {
      setValue([]);
    }
  }, [treeData, vendorLevel, setValue]);

  useEffect(() => {
    changeTreeData();
  }, [categoriesList, vendorLevel, selectedChildVendors, changeTreeData]);
  

  return (
    <TreeSelect
      multiple
      disabled={disabled}
      className='form-select-dropdown-custom'
      maxTagCount={3}
      notFoundContent={(!selectedChildVendors || selectedChildVendors.length === 0) ? "Please select child vendor(s) to see associated categories": null}
      loading={!loaded && (!selectedChildVendors || selectedChildVendors.length === 0)}
      suffixIcon={!loaded && (!selectedChildVendors || selectedChildVendors.length !== 0) ? <Spin className='custom-spin' size="small"/> : null}
      {...categoriesProps}
    />
  )
}