import { useEffect } from 'react';


export const useUpdateWorkbookData = (data, page, id) => {
    useEffect(() => {
        if (!id || !data || data.type !== "update" || page !== "yearly") return () => { };
        const sigma_iframe = document.getElementById(id);

        if (sigma_iframe) {
            sigma_iframe.contentWindow.postMessage(
                {
                    type: 'workbook:variables:update',
                    variables: data.data,
                },
                'https://app.sigmacomputing.com',
            )
        }
        
        
        return () => {}

    }, [JSON.stringify(data), page, id]);
}