import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select } from 'antd';
import { scrollToBottom, sortObject } from '../../utils/helpers';
import { useVendorsForBrokers } from '../../hooks/useVendorsForBroker';
import { useBrokersForManagePage } from '../../hooks/useBrokersForManagePage';
import { useNavigate } from 'react-router-dom';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import vendorsService from '../../axios/vendorsService';
import CMS from '../../cms/cms.json';



/**
 * 
 * @component DynamicFormComponent - Dynamic form component to support adding and editng brokers
 * @param {title}  title - title to display for dynamic form "Add Broker" | "Edit Broker"
 * 
*/
export const DynamicFormComponent = ({ action, title, initialData, id = "dynamic-card-div" }) => {
    const navigate = useNavigate();

    const [lastCardChanged, setLastCardChanged] = useState();
    const [componentDisabled, setComponentDisabled] = useState(false);
    const [areaOptions, setAreaOptions] = useState({});
    const [vendors, setVendors] = useState();
    const [initData, setInitData] = useState();

    const [form] = Form.useForm();
    const { vendorsForBrokers } = useVendorsForBrokers(action);
    const { brokersForManagePageInfo, brokersForManagePageInfoLoading } = useBrokersForManagePage();

    useEffect(() => {
        form.resetFields();
    }, [initData])

    useEffect(() => {
        if (initialData && initialData.items) {
            setInitData({
                items: initialData.items,
                brokerName: initialData.brokerName
            });
        } else {
            setInitData(undefined);
        }

    }, [JSON.stringify(initialData)])

    useEffect(() => {
        if (vendorsForBrokers) {
            const vendorsForBrokersDeepCopy = JSON.parse(JSON.stringify(vendorsForBrokers));

            if (initialData) {
                const brokerName = initialData.items.brokerName;
                const savedVendors = initialData.items.map(item => item.vendorName);
                // console.log("SAVED VENDORS: ", savedVendors);
                const savedVendorOptions = savedVendors.map(vendor => {
                    return {
                        vendor_group_name: vendor.label,
                        vendor_group_number: vendor.value
                    };
                });

                savedVendorOptions.forEach(option => {
                    const optionExists = vendorsForBrokersDeepCopy.find(vendor => vendor.vendor_group_number === option.vendor_group_number);
                    if (!optionExists) {
                        vendorsForBrokersDeepCopy.push(option);
                    }
                });

                async function getAreaOptions() {
                    const areaOptions = await Promise.all(initialData.items.map(async (data) => {
                        const { vendorName } = data;
                        const areaOptionsList = await vendorsService.getBrokerAvailableAreas(brokerName, vendorName.value);
                        let savedAreasOptions = initialData.items.filter(data => data.vendorName.value === vendorName.value)[0].brokerAreas;
                        let returnAreaOptionList = savedAreasOptions;
                        if (areaOptionsList) {
                            const mappedAreaOptionsList = areaOptionsList.map(option => {
                                return {
                                    label: option.area_name,
                                    value: option.area_id
                                }
                            });

                            returnAreaOptionList = [...savedAreasOptions, ...mappedAreaOptionsList];
                        }

                        return returnAreaOptionList?.sort(sortObject);
                    }));

                    let areaOptionsRetVal = {};
                    for (let i = 0; i < areaOptions.length; i++) {
                        areaOptionsRetVal[i] = areaOptions[i];
                    }

                    setAreaOptions(areaOptionsRetVal);
                }

                getAreaOptions();
            }

            setVendors(vendorsForBrokersDeepCopy);
        }

    }, [JSON.stringify(vendorsForBrokers)])


    // helper functions
    const filteredVendors = (cardName, vendors) => {
        const formData = form.getFieldsValue();
        const specificFormData = form.getFieldsValue([['items', cardName, 'vendorName']])?.items[cardName]?.vendorName?.value;


        let vendorOptions = JSON.parse(JSON.stringify(vendors));
        let selectedVendors = formData?.items?.map(vendor => vendor?.vendorName?.value);
        selectedVendors = selectedVendors?.filter(vendor => vendor !== specificFormData);

        vendorOptions = vendors?.filter((vendor) => {
            return !selectedVendors?.includes(vendor?.vendor_group_number);
        });

        if (vendorOptions) {
            vendorOptions = JSON.parse(JSON.stringify(vendorOptions));
        }

        return vendorOptions;
    }

    const getVendorOptions = (cardName, vendors) => {
        const vendorOptions = filteredVendors(cardName, vendors);

        return vendorOptions?.map((vendor) => {
            return {
                label: vendor?.vendor_group_name,
                value: vendor?.vendor_group_number
            };
        })?.sort(sortObject);
    }

    const onVendorChange = async (e, cardName) => {
        setLastCardChanged(cardName); // purpose of setter is to re render the component
        const specificFormData = form.getFieldsValue([['items', cardName]]); //?.items[cardName]?.areaName?.value;
        const vendor_group_name = specificFormData?.items[cardName]?.vendorName?.label;
        const vendor_group_number = specificFormData?.items[cardName]?.vendorName?.value;

        if (vendor_group_name && vendor_group_number) {
            let brokerAreas = await vendorsService.getBrokerAvailableAreas(vendor_group_name, vendor_group_number);
            brokerAreas = brokerAreas?.map((vendor) => {
                return {
                    label: vendor?.area_name,
                    value: vendor?.area_id
                }
            })?.sort(sortObject);

            const currentFormData = form.getFieldsValue();
            const areaOptionsTemp = {};
            areaOptionsTemp[cardName] = brokerAreas;

            const card = currentFormData.items[cardName];
            if (card) {
                card.brokerAreas = [];
            }

            setAreaOptions({ ...areaOptions, ...areaOptionsTemp });
        }
    }

    const getAreaOptions = (cardName) => {
        const currentFormData = form.getFieldsValue();

        if (areaOptions.hasOwnProperty(cardName)) {
            const allOption = [{
                label: "All",
                value: "all"
            }];

            if (areaOptions[cardName]) {
                return [...allOption, ...areaOptions[cardName]];
            } else {
                return [...allOption];
            }
        }
    }

    const onAreaChange = (area_ids, cardName) => {
        let selectedAreas = null;
        const currentFormData = form.getFieldsValue();

        const card = currentFormData.items[cardName];
        const currentlySelectedAreas = card?.brokerAreas;
        const allAreasSelected = currentlySelectedAreas.filter(area => area !== "all")?.length === areaOptions[cardName]?.length;

        if (area_ids.includes("all") && currentlySelectedAreas && !allAreasSelected) {
            selectedAreas = areaOptions[cardName].filter(area => area !== "all");
        } else if (area_ids.includes("all") && allAreasSelected) {
            selectedAreas = [];
        } else {
            selectedAreas = areaOptions[cardName].filter((area_obj) => {
                return area_ids.includes(area_obj.value);
            });
        }

        card.brokerAreas = selectedAreas;
    }

    const adjustAreaOptions = (cardName) => {
        let adjustedAreaOptions = {};
        const iterableAreaOptions = Object.entries(areaOptions);

        iterableAreaOptions.forEach((option) => {
            const [key, value] = option;
            if (key > cardName && key !== 0) {
                adjustedAreaOptions[key - 1] = value;
            } else {
                adjustedAreaOptions[key] = value;
            }
        });

        return adjustedAreaOptions;
    }

    const compareValues = (formDataItems, initialData) => {
        if (!initialData) return true;

        const initialItems = initialData.items;
        if (formDataItems.length !== initialItems.length) {
            return true;
        }

        for (let i = 0; i < formDataItems.length; i++) {
            const data = formDataItems[i];
            const vendorName = data.vendorName.label;
            const brokerAreas = data.brokerAreas.map((area) => area.label);

            const initialVendorData = initialItems.find((item) => item.vendorName.label === vendorName);
            if (!initialVendorData) {
                return true;
            }

            const initialBrokerAreas = initialVendorData?.brokerAreas?.map((area) => area.label);

            if (brokerAreas?.length !== initialBrokerAreas?.length) {
                return true;
            }

            const areasDifferent = brokerAreas.some((area) => !initialBrokerAreas.includes(area));
            if (areasDifferent) {
                return true;
            }
        }

        return false;
    }

    const getInsertBrokerAreasPayload = (items, brokerName) => {
        const payload = [];
        items.forEach(item => {
            const { brokerAreas, vendorName } = item;
            brokerAreas.forEach(area => {
                payload.push({ area_id: area.value, area_name: area.label, vendor_group_number: vendorName.value, broker_group_name: brokerName })
            });
        });

        return payload;
    }

    const saveBroker = async (formData) => {
        setComponentDisabled(true);
        const { items, brokerName } = formData;
        const didValuesChange = compareValues(items, initialData);
        let brokerResponse = null;
        let brokerAreasResponse = null;


        if (didValuesChange) {
            const area_information = getInsertBrokerAreasPayload(items, brokerName);
    
            if (action === "add") {
                brokerResponse = await vendorsService.insertBroker(brokerName);
                brokerAreasResponse = await vendorsService.insertBrokerAreas(area_information, brokerName);
            } else {
                brokerResponse = await vendorsService.updateBroker(initialData.brokerNumber, brokerName); // updating name is not currently supported
                brokerResponse = true;
                brokerAreasResponse = await vendorsService.updateBrokerAreas(area_information, brokerName);
            }
        }

        if ((brokerResponse && brokerAreasResponse) || !didValuesChange) {
            const msg = action === "add" ? "Successfully published broker information" : "Broker information successfully edited"
            const state = { variation: "success", msg: msg };
            navigate('/manage-broker', { state: state });
        } else {
            const state = { variation: "error", msg: "Please contact an administrator" };
            navigate('/manage-broker', { state: state });
        }

        setComponentDisabled(false);
    }



    return (
        vendors ?
            <div className='add-edit-broker-form'>
                <h2>{title}</h2>

                <Form
                    form={form}
                    onFinish={saveBroker}
                    name="dynamic_form_complex"
                    style={{
                        width: "75%"
                    }}
                    autoComplete="off"
                    layout='vertical'
                    initialValues={initData}
                >

                <Form.Item name="brokerName" label="Broker Name:" rules={[
                    {
                        message: 'Please enter a Broker Group Name',
                        validator: (_, value) => {
                            if (value) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject();
                            }
                        }
                    },
                    {
                        message: 'Enter Unique Broker Group Name.',
                        validator: (_, value) => {
                            if (action === "add" && brokersForManagePageInfo?.map((broker) => broker?.broker_group_name.toUpperCase())?.includes(value?.toUpperCase())) {
                                return Promise.reject();
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]}>
                    <Input disabled={action === "edit" ? true : false}
                        className='input'
                        placeholder='Name'
                    />
                </Form.Item>

                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <div id="broker" className='broker-dynamic-form-div'>

                                <hr style={{ marginBottom: "23px" }} />
                                <div
                                    className="dynamic-form-cards"
                                    id="dynamic-card-div"
                                >
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Card
                                            size="small"
                                            key={key}
                                            extra={
                                                fields.length > 1 ? (
                                                    <CloseOutlined
                                                        onClick={() => {
                                                            remove(name);
                                                            const updatedAreaOptions = adjustAreaOptions(name);
                                                            setAreaOptions(updatedAreaOptions);
                                                        }}
                                                    />
                                                )
                                                    : null}
                                        >
                                            {/* Nest Form.List */}
                                            <Form.Item {...restField} label="Vendor:" name={[name, 'vendorName']}>
                                                <Select
                                                    className='vendor-select'
                                                    placeholder='Select Vendor(s)'
                                                    onChange={(e) => onVendorChange(e, name)}
                                                    labelInValue
                                                    options={getVendorOptions(name, vendors)}
                                                    showSearch
                                                    notFoundContent={null}
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                />
                                            </Form.Item>
                                            {/* Nest Form.List */}
                                            <Form.Item {...restField} label="Area:" name={[name, 'brokerAreas']}>
                                                <Select
                                                    className='area-select'
                                                    placeholder="Select Area(s)"
                                                    mode="multiple"
                                                    onChange={(e) => onAreaChange(e, name)}
                                                    autoClearSearchValue={false}
                                                    options={getAreaOptions(name)}
                                                    // suffixIcon={areaOptionsLoading ? <Spin className='custom-spin' size="small" /> : <DownOutlined />}
                                                    notFoundContent={null}
                                                />
                                            </Form.Item>
                                        </Card>
                                    ))}

                                </div>

                                <div className='form-buttons'>
                                    <Form.Item>
                                        <Button
                                            className="cancel-button"
                                            onClick={() => { add(); }}
                                            disabled={componentDisabled}
                                        >
                                            <div
                                                className='button-items'
                                            >
                                                {CMS.pages.find(o => o.name === "addVendor")?.title}
                                                <AddIcon
                                                    className='save-icon'
                                                />
                                            </div>
                                        </Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            className="save-button"
                                            htmlType="submit"
                                            disabled={componentDisabled}
                                        >
                                            <div
                                                className='button-items'
                                            >
                                                {CMS.pages.find(o => o.name === "save")?.title}
                                                <SaveOutlinedIcon
                                                    className='save-icon'
                                                />
                                            </div>
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                    </Form.List>
                </Form>
            </div>
            : null);
};




