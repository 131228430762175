import vendorsService from "../../../../axios/vendorsService";


export const publishToTables = async (jwt, brokerAreas, value, values, selectedChildVendors, categoriesList) => {
    if (selectedChildVendors && selectedChildVendors.length === 0) return

    const subscriptionId = values['vendorRadioSelection'];
    const subscriptionName = subscriptionId === '1' ? 'Viewer' : subscriptionId === '2' ? 'Portfolio' : 'Category';

    const errorObject = {
        status: false,
        msg: "Error, please contact administrator"
    };

    const successObject = {
        status: true,
        msg: "Vendor Group entry successfully published"
    };

    const createParentVendor = await vendorsService.createParentVendor(
        null,
        values['Vendor Group Name'] ? values['Vendor Group Name'] : ``,
        values['Primary Contact Name'] ? values['Primary Contact Name'] : ``,
        values['Primary Contact Email'] ? values['Primary Contact Email'] : ``,
        values['Primary Contact Phone'] ? values['Primary Contact Phone'] : ``,
        values['Secondary Contact Name'] ? values['Secondary Contact Name'] : ``,
        values['Secondary Contact Email'] ? values['Secondary Contact Email'] : ``,
        values['Secondary Contact Phone'] ? values['Secondary Contact Phone'] : ``,
        values['Category Director'] ? values['Category Director'] : ``,
        values['Category Manager'] ? values['Category Manager'] : ``,
        values['vendorRadioSelection'] ? values['vendorRadioSelection'] : ``,
        values['vendorRadioSelection'] ? values['vendorRadioSelection'] === '1' ? 'Viewer' : subscriptionId === '2' ? 'Portfolio' : 'Category'  : ``,
        values['isBroker'] ? values['isBroker'] : 'false',
        values['isCustomer'] ? `${values['isCustomer']}` : 'false'
    )
    .then(async(res) => {
        if (!res) throw Error("Empty response");

        const formattedChildVendorInformation = selectedChildVendors
        .map((child) => {
            return {
                vendorName: child.label,
                vendorId: child.id
            }
        });

        const childVendorRes = await vendorsService.createChildVendor(jwt.current, formattedChildVendorInformation, values['Vendor Group Name'])
        if (!childVendorRes) {
            console.error("Failed to publish child vendors");
            return errorObject;
        }

        if (values['isBroker'] && brokerAreas && brokerAreas.length !== 0) {
            brokerAreas = brokerAreas.filter((brokerArea) => {
                return values["Area"].includes(brokerArea.id)
            });

            brokerAreas = brokerAreas.map((brokerArea) => {
                return {
                    area_name: brokerArea.label,
                    area_id: brokerArea.id
                };
            })

            const brokerAreasRes = await vendorsService.insertBrokerAreas(brokerAreas, values['Vendor Group Name']);
            if (!brokerAreasRes) {
                console.error("Failed to publish brokers");
                return errorObject;
            }
        }

        if (subscriptionName === "Category") {
            const categories = [];
            const parentsArr = categoriesList.map((obj) => obj.parent);

            for (let i = 0; i < value.length; i++) {
                const selectedValue = value[i];
                if (parentsArr.includes(selectedValue)) {
                    const selectedParent = categoriesList.filter((obj) => obj.parent === selectedValue).pop();
                    const selectedChildrenArray = selectedParent.children;
                    const formattedChildren = selectedChildrenArray.map(childCategory => {
                        return childCategory;
                    });
                    categories.push(...formattedChildren);
                } else {
                    categories.push(selectedValue);
                }
            }

            const vendorCategoryRes = await vendorsService.createVendorCategory(jwt.current, values['Vendor Group Name'], categories);
            if (!vendorCategoryRes) {
                console.error("Failed to publish vendor categories");
                return errorObject
            }
        }

        return successObject;
    })
    .catch((error) => {
        console.error(error);
        return errorObject
    });

    return createParentVendor;
}