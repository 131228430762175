import { filterArrayOfObjectsByString } from "../../utils/filterArrayOfObjectsByString";
import { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSubscribedVendorAttributes } from "../../hooks/useSubscribedVendorAttributes";
import { useBrokersForManagePage } from "../../hooks/useBrokersForManagePage";
import { useGetBrokers } from "../../hooks/useGetBrokers";
import { AmplifyAlertBanner } from '../AmplifyAlertBanner';
import { Input, Modal, Space } from "antd";
import { manageVendorColumns } from "../../pages/admin/broker/vendorColumns";
import { manageBrokerColumns } from "../../pages/admin/broker/brokerColumns";
import { Button } from "@aws-amplify/ui-react";
import { EditOutlined } from "@mui/icons-material";
import { DataTable } from "./DataTable";
import { UserGroupMap } from "../../pages/admin/UserGroupMap";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import vendorsService from "../../axios/vendorsService";
import BlockIcon from '@mui/icons-material/Block';
import CMS from "../../cms/cms.json"
import "../../pages/admin/vendor/ManageVendors/ManageVendors.css"
import "../../App.css"


const { Search } = Input;

/**
 * 
 * @component ManageVendorBroker - Single dynamic component to manage Vendors and Brokers
 * @param {String}  userGroup - "vendor" or "broker"
 *
*/
export const Manage = ({ userGroup }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [newData, setNewData] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [modalMsg, setModalMsg] = useState('');
    const [config, setConfig] = useState({ variation: "", msg: "" })

    const { subscribedVendorAttributes, subscribedVendorAttributesLoading } = useSubscribedVendorAttributes(userGroup, newData);
    const { brokersForManagePageInfo, brokersForManagePageInfoLoading } = useBrokersForManagePage();
    const { brokerList, brokerListLoaded } = useGetBrokers();

    const baseColumns = [
        {
            title: "Edit",
            align: "center",
            key: "action",
            render: (_, row) => (
                <Space>
                    <Button
                        size="small"
                        variation="link"
                        onClick={() => {
                            const { key, ...data } = row;

                            if (userGroup === "vendor") {
                                const currentVendorNames = dataSource.map((vendor) => {
                                    return String.prototype.toUpperCase.call(vendor.vendorGroupName);
                                });
                                navigate("/edit-vendor", { state: { ...data, currentVendorNames: currentVendorNames } });
                            } else {
                                navigate("/edit-broker", { state: { ...data } });
                            }
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    {

                        row.status === 'active' ?
                            <Button key='Deactivate User' title="Deactivate Vendor" variation='link' size='small' onClick={() => {
                                setCurrentRow(row);
                                setModalMsg('Do you want to deactivate this vendor?');
                                setConfirmationModal(true);
                            }}>
                                <BlockIcon />
                            </Button> :
                            <Button key='Activate User' title="Activate Vendor" variation='link' size='small' onClick={() => {
                                setCurrentRow(row);
                                setModalMsg('Do you want to activate this vendor?');
                                setConfirmationModal(true);
                            }}>
                                <ManageAccountsIcon />
                            </Button>
                    }
                </Space>
            ),
        },
    ];

    const columns = useMemo(() => {
        let cols = [];
        switch (userGroup) {
            case "broker":
                cols = cols.concat(manageBrokerColumns);
                break;
            case "vendor":
                cols = cols.concat(manageVendorColumns);
                break;
            default:
                console.log("no additional columns to display");
        }
        cols = cols.concat(baseColumns);
        return cols
    }, [userGroup, JSON.stringify(baseColumns)])

    const searchPlaceholder = useMemo(() => {
        return `Search ${userGroup === "vendor" ? "Vendors" : "Brokers"}`
    }, [userGroup])


    const onSearch = (event) => {
        let value = event?.currentTarget?.value ?? event;
        let valueInput = value.toLowerCase();
        let filteredData = [];
        filteredData = filterArrayOfObjectsByString(valueInput, dataSource)

        setDataTable(filteredData);

        return filteredData;
    };

    const onCancel = () => {
        setConfirmationModal(false);
    }

    const deactivateVendor = async (row) => {
        setConfirmationModal(false);
        setLoading(true);
        let action = 'deactivate';
        await vendorsService.manageVendorStatus(row.vendorGroupNumber, action).then((res) => {
            if (res?.data?.data?.manageVendorStatus) {
                setNewData(true);
            }
        }).catch((err) => {
            console.error("Error deactivating vendor: ", err);
            setConfig({ variation: "error", msg: "Error deactivating vendor, please contact admin" });
        }).finally(() => {
            setLoading(false);
        })
    }

    const activateVendor = async (row) => {
        setConfirmationModal(false);
        setLoading(true);
        let action = 'activate';
        await vendorsService.manageVendorStatus(row.vendorGroupNumber, action).then((res) => {
            if (res?.data?.data?.manageVendorStatus) {
                setNewData(true);
            }
        }).catch((err) => {
            console.error("Error activating vendor: ", err);
            setConfig({ variation: "error", msg: "Error activating vendor, please contact admin" });
        }).finally(() => {
            setLoading(false);
        })
    }


    useEffect(() => {
        if (brokersForManagePageInfo && brokerList && userGroup === "broker") {
            setDataTable(brokersForManagePageInfo);
        } else if (subscribedVendorAttributes && userGroup === "vendor") {
            setDataTable(subscribedVendorAttributes);
        }

    }, [JSON.stringify(subscribedVendorAttributes), JSON.stringify(brokersForManagePageInfo), JSON.stringify(brokerList)])

    useEffect(() => {
        if (newData && dataTable && dataTable.length > 0) {
            setLoading(false);
            setDataSource(dataTable);
            setDataTable(dataTable);
            setNewData(false);

            // set filter options form data now that table + data has loaded
        }
    }, [JSON.stringify(dataTable)]);

    useEffect(() => {
        if (location.state) {
            const { variation, msg } = location.state;
            setConfig({ variation: variation, msg: msg });
        }
    }, [location.state])


    return (
        <div id='vendor-list-body'>
            <AmplifyAlertBanner
                isDismissible={true}
                config={config}
            />
            <div id='vendor-list-body'>
                <div className='manage-control'>
                    <h2 className='manage-vendors-title'>
                        {CMS.pages.find(o => o.name === UserGroupMap[userGroup])?.title}
                    </h2>
                </div>

                <Search
                    className="search-component"
                    placeholder={searchPlaceholder}
                    allowClear
                    onChange={onSearch}
                    onSearch={onSearch}
                />

                <DataTable
                    columns={columns}
                    data={dataTable}
                    dataLoading={subscribedVendorAttributesLoading}
                    actionLoading={loading}
                />
                <Modal
                    open={showConfirmationModal}
                    onOk={() => currentRow.status === 'active' ? 
                        deactivateVendor(currentRow) : activateVendor(currentRow)
                    }
                    onCancel={() => onCancel()}
                >
                    <p>{modalMsg}</p>
                </Modal>
            </div>
        </div>
    );
};
