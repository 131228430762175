import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FiltersContext } from '../components/Navigation/Routes';
import { useContext, useMemo } from 'react';

export const CustomToggleGroup = () => {
    const context = useContext(FiltersContext);
    const {
        viewType, setViewType, subId, impersonatorInfo
    } = context;

    const impersonating = useMemo(() => Object.keys(impersonatorInfo).length > 0, [JSON.stringify(impersonatorInfo)]);
    const shouldDisplayToggle = useMemo(() => {
        if (impersonating) {
            const shouldShowToggle = impersonatorInfo?.subscriptionId === 3;
            if (!shouldShowToggle) {
                setViewType('basic');
            }
            return impersonating && shouldShowToggle;
        }
    }, [impersonating]);


    if ((!impersonating && subId === 3) || (impersonating && shouldDisplayToggle)) {
        return (
            <ToggleButtonGroup
                className='toggle-button-mode'
                value={viewType}
                exclusive
                sx={{ marginRight: "10px" }}
                onChange={() => {
                    viewType === "basic" ? setViewType("insights") : setViewType("basic");
                }} >
                <ToggleButton className='dq-toggle-button' value="basic">Portfolio</ToggleButton>
                <ToggleButton className='dq-toggle-button' value="insights">Category</ToggleButton>
            </ToggleButtonGroup>
        );
    } else {
        return (
            <ToggleButtonGroup
                className='toggle-button-mode'
                value={viewType}
                exclusive
                sx={{ marginRight: "10px" }}
                disabled
                onChange={() => {
                    return false;
                }} >
                <ToggleButton className='dq-toggle-button' value="basic">Portfolio</ToggleButton>
            </ToggleButtonGroup>
        );
    }
};