import { useState, useEffect } from 'react';
import { sortObject } from '../utils/helpers';
import vendorsService from "../axios/vendorsService";
import featureToggle from '../featureToggle.json';


export const useSelectedBrokerAreas = (isChecked, vendorGroupNumber) => {
    const [selectedBrokerAreas, setSelectedBrokerAreas] = useState(null);
    const [selectedBrokerAreasLoaded, setSelectedBrokerAreasLoaded] = useState(false);

    useEffect(() => {
        setSelectedBrokerAreasLoaded(true);
        setSelectedBrokerAreas(null);

        if (isChecked) {
            const fetchBrokerAreas = async() => {
                setSelectedBrokerAreasLoaded(false);
                const data = await vendorsService.getSelectedBrokerAreas(vendorGroupNumber, null);
                let brokerData = [];
                if (data) {
                    brokerData = data.map((broker) => {
                        return {
                            label: broker.area_name,
                            id: broker.area_id
                        };
                    });
                    brokerData = brokerData.sort(sortObject);
                }
                
                setSelectedBrokerAreas(brokerData);
                setSelectedBrokerAreasLoaded(true);
            }
    
            if (!featureToggle[process.env.REACT_APP_ENVIRONMENT]["broker"]) return;
            fetchBrokerAreas();
        }
    }, [isChecked, vendorGroupNumber]);


    return { selectedBrokerAreas, setSelectedBrokerAreas, selectedBrokerAreasLoaded };
}