import { DynamicFormComponent } from "../../../components/VendorAdmin/DynamicFormComponent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import React from "react";
import vendorsService from "../../../axios/vendorsService";


export function BrokerView({ action }) {
    const location = useLocation();
    const [initialData, setInitialData] = useState();

    useEffect(() => {
        if (action === "add") {
            setInitialData(undefined);
        }
    }, [action]);

    useEffect(() => {
        const fetchInitialBrokerData = async (broker_group_name) => {
            const initialBrokerData = await vendorsService.getSelectedBrokerAreas(null, broker_group_name);
            const vendorGroupNumbers = [];
            const formattedData = [];

            if (initialBrokerData) {
                initialBrokerData.forEach((data) => {
                    const { vendor_group_number } = data;
                    if (!vendorGroupNumbers.includes(vendor_group_number)) {
                        vendorGroupNumbers.push(vendor_group_number);
                    }
                });

                vendorGroupNumbers.forEach((venNum) => {
                    const vendorInfo = initialBrokerData.filter(data => data.vendor_group_number === venNum);
                    formattedData.push({
                        vendorName: { label: vendorInfo[0].vendor_group_name, value: venNum },
                        brokerAreas: vendorInfo.map(data => {
                            return {
                                label: data.area_name,
                                value: data.area_id
                            };
                        })
                    });
                });
            }

            const newInitialData = {
                items: formattedData,
                brokerName: location.state.broker_group_name,
                brokerNumber: location.state.broker_group_number
            };

            setInitialData(newInitialData);
        }

        if (location.state) {
            const { broker_group_name } = location.state;
            fetchInitialBrokerData(broker_group_name);
        }
    }, [location.state]);


    return (
        (initialData && action === "edit") || action === "add" ?
            <DynamicFormComponent
                action={action}
                title={`${action === "add" ? "Add" : "Edit"} Broker`}
                initialData={initialData}
            />
            : <Spin />
    );
};