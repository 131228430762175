import React, { useEffect, useState, useRef} from 'react';
import { Auth } from 'aws-amplify';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Loading from '../../components/Loading';

import {
    Form,
    Input,
    Button,
  } from 'antd';
import CMS from '../../cms/cms.json';
import usersService from '../../axios/usersService';

export function MyProfile() {

    const [firstName, setFirstName] = useState("");
    const [vendor, setVendor] = useState("");
    const [vendorGroupNumber, setVendorGroupNumber] = useState(0);
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [loaded, setLoaded] =useState(false);
    const [componentDisabled, setComponentDisabled] = useState();
    const [internal, setInternal] = useState(false);
    const [form] = Form.useForm();
    const jwt = useRef();

    useEffect(() => {
        Auth.currentSession().then(async (session) => {
            const userRef = session.getIdToken().payload;
            jwt.current = session.getIdToken().getJwtToken();
            let userInfoRes;
            if (userRef){
                if(userRef["cognito:groups"].includes('Internal')){
                    userInfoRes = null;
                    setInternal(true);
                    setVendor('Internal');
                }else{
                    userInfoRes = await usersService.userInfo(userRef.email);
                    setVendor(userInfoRes?.vendorName);
                }
                setFirstName(userInfoRes? userInfoRes.firstName : userRef.name);
                setLastName(userInfoRes? userInfoRes.lastName : userRef.family_name);
                setEmail(userInfoRes? userInfoRes.mail : userRef.email);
                setPhone(userInfoRes? userInfoRes.phone : userRef.phone_number);
                setVendorGroupNumber(userInfoRes? userInfoRes.vendorGroupNumber : 0);


                setLoaded(true);
                setComponentDisabled(false);
            }

        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }, [email]);
    
  const onSubmit = async (values) =>{
    setComponentDisabled(true);

    await usersService.editUserAtt( email, email, vendorGroupNumber, values["firstName"], values["lastName"],values["phone"]).then((res)=>{
      res ? console.log("edit User response",res) : console.error("please verify your entries.");
    }).catch((err) =>{
      console.error("ERROR editing user: ", err);
    });
    setComponentDisabled(false);
    }
    return(!loaded ? <Loading /> :
            <div id='profile-page'>
                <h2 className='page-title'>
                {CMS.pages.find(o => o.name === "myProfile")?.title}
                </h2>
                <Form form={form} initialValues={{firstName, lastName, email, phone ,vendor}} onFinish={onSubmit} labelCol={{span: 40}} wrapperCol={{ span: 30 }} layout="vertical"> 
                    <div className='attribute-box'>
                        <Form.Item label="FIRST NAME:" name="firstName" required rules={[{required:true}]}>
                            <Input  disabled={internal}/>
                        </Form.Item>
                    
                        <Form.Item label="LAST NAME:" name="lastName" required rules={[{required:true}]}>               
                            <Input disabled={internal}/>
                        </Form.Item>
            
                        <Form.Item label="EMAIL:" name= "email" required rules={[{required:true}]}>
                            <Input disabled={true}/>
                        </Form.Item>
                        
                        <Form.Item label="PHONE:" name="phone" >
                            <Input disabled={internal}/>
                        </Form.Item>
                    
            
                        <Form.Item label="VENDOR:" name="vendor" required rules={[{required:true}]}>
                            <Input disabled={true}/>
                        </Form.Item>
                        <h5 className='profile-email-message'>Please contact an admin if you wish to update your email.</h5>
                    </div>
                    <div className='profile-buttons'>
                        <Form.Item> 
                            <Button type='primary' className="save-button" htmlType="submit" disabled={(componentDisabled || internal)}>
                                <div className='button-items'>
                                    {CMS.pages.find(o => o.name === "save")?.title}
                                    <SaveOutlinedIcon className='save-icon'/>
                                </div>
                            </Button>
                        </Form.Item>

                    </div>
                </Form>
      </div>
    );
};