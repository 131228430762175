import dayjs from 'dayjs';


export function getCannedTimeframeArr(selectedCannedReport) {
    const timeframeArr = []

    if (selectedCannedReport === "YTD") {
        selectedCannedReport = getYTDWeeks();
    }

    for(let i = selectedCannedReport; i > 0; i--) {
        const week = dayjs()
                        .locale('en-GB')
                        .day(0)
                        .subtract(i, 'week')
                        .format('YYYY-MM-DD (ww)');
        timeframeArr.push(week);
    }

    return timeframeArr
}

export function getMatchState(currentTimeframe,switchOn){
    const today = dayjs().locale('en-GB');
    const currWeek = today.day(0).format('YYYY-MM-DD (ww)');
    const lastAvailableDate = dayjs(currWeek)
    .subtract(108, 'week')
    .locale('en-GB')
    .format('YYYY-MM-DD');

    const prev = getDatesMatchCurrent(currentTimeframe,switchOn);

    let isEarlier = prev.some(dateString => {
        let date = dayjs(dateString.split(' ')[0]);
        return date.isBefore(lastAvailableDate); 
    });
    return(isEarlier);

}
export function getDatesMatchCurrent(currTimeframeArr, switchOn){
    const prev = switchOn ? 
        // creates date array for last period
        currTimeframeArr.map((date) => {
            return dayjs(date)
                    .subtract(currTimeframeArr.length, 'week')
                    .locale('en-GB')
                    .format('YYYY-MM-DD (ww)')
        }):

        // creates date array for last year
        currTimeframeArr.map((date) => {
            return dayjs(date)
                    .subtract(1, 'year')
                    .locale('en-GB')
                    .day(0).add(1, 'week')
                    .format('YYYY-MM-DD (ww)')
        });

    return prev
}
export function getDatesPreviousYear(selectedCannedReport, currTimeframeArr, switchOn) {
    const today = dayjs().locale('en-GB');
    const currWeek = today.day(0);
    const firstWeekOfYr = today.startOf('year');

    if (selectedCannedReport === "YTD") {
        selectedCannedReport = currWeek.week() - firstWeekOfYr.week();
    }

    const prev = switchOn ? 
        // creates date array for last period
        currTimeframeArr.map((date) => {
            return dayjs(date)
                    .subtract(selectedCannedReport, 'week')
                    .locale('en-GB')
                    .format('YYYY-MM-DD (ww)')
        }):

        // creates date array for last year
        currTimeframeArr.map((date) => {
            return dayjs(date)
                    .subtract(1, 'year')
                    .locale('en-GB')
                    .day(0).add(1, 'week')
                    .format('YYYY-MM-DD (ww)')
        });

    return prev
}


export function getYTDWeeks() {
    const today = dayjs().locale('en-GB');
    const currWeek = today.day(0);
    const firstWeekOfYr = today.startOf('year')

    return currWeek.week() - firstWeekOfYr.week() 
}


export function encodeDates(timeframeArr) {
    const formattedCurrArray = [];

    timeframeArr.forEach((item) => {
        const formattedItem = item.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
        formattedCurrArray.push(formattedItem)
    })

    const timeframeVals = formattedCurrArray.join(',');

    return timeframeVals
}