import { IconButton, Autocomplete, Button, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useSubscribedVendorAdminAttributes } from '../hooks/useSubscribedVendorAdminAttributes';
import { Spin } from "antd";
import ClearIcon from '@mui/icons-material/Clear';


export default function CustomModal(props) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState();

    const { subscribedVendorAdminAttributes } = useSubscribedVendorAdminAttributes(props.open);

    // callback functions
    const handleChange = (vendor) => {
        props.handleVendorChange(vendor);
        setIsButtonDisabled(vendor ? subscribedVendorAdminAttributes.includes(vendor.vendorGroupName) : true);
    }

    const handleClick = () => {
        const { selectedVendor } = props;
        const { vendorGroupName: name, vendorRole: sfRole, ...vendorInfo } = selectedVendor;
        const impersonatorInfo = { name, sfRole, ...vendorInfo };
        props.handleImpersonating(impersonatorInfo);
    }

    const handleClose = () => {
        props.handleCloseImpersonatorMenu();
    }

    const onOpen = async () => {
        props.handleOpenModal();
    }


    useEffect(() => {
        if (props.selectedVendor == null) {
            setIsButtonDisabled(true);
            setButtonColor("#717073");
        } else {
            setIsButtonDisabled(subscribedVendorAdminAttributes.includes(props.selectedVendor.vendorName));
            setButtonColor("#76AA35");
        }
    }, [JSON.stringify(props.selectedVendor)])


    return (
        <div className="impersonate-popup">
            <div className="impersonate-inner">
                <IconButton
                    onClick={handleClose}
                    className="close-button"
                >
                    <ClearIcon color={"error"} />
                </IconButton>
                <h2 className="popup-title">Impersonate a Vendor </h2>
                <h4 className="popup-description"> Choose a vendor to impersonate</h4>
                <label htmlFor="cars">View profile as:</label>
                <div className="inner-container">
                   {
                        subscribedVendorAdminAttributes ?
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            onChange={(event, vendor) => { handleChange(vendor) }}
                            value={props.selectedVendor}
                            onOpen={onOpen}
                            options={subscribedVendorAdminAttributes ? subscribedVendorAdminAttributes : []}
                            getOptionLabel={(option) => option?.vendorGroupName}
                            isOptionEqualToValue={(option, value) => option?.vendorRole=== value?.vendorRole}
                            sx={{ width: "100%", textAlign: "center" }}
                            renderInput={(params) => <TextField {...params} label="Vendors" />}
                        />
                        :
                        <Spin className='custom-spin'/>
                    }

                    <Button
                        onClick={handleClick} // define handleImpersonating in AlertImpersonate
                        style={{ marginTop: "20px", color: "white", width: "100%", backgroundColor: buttonColor }}
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        disabled={isButtonDisabled}
                    >
                        Impersonate
                    </Button>
                </div>
            </div>
        </div>
    )
}





