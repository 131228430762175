import { useState, useEffect } from 'react';
import vendorsService from "../axios/vendorsService";


export const useSubscribedVendors = (vendorGroupNumber, formSubmitted) => {
    const [subscribedVendors, setSubscribedVendors] = useState(null);
    const [subscribedVendorsLoaded, setSubscribedVendorsLoaded] = useState(false);

    useEffect(() => {
        setSubscribedVendorsLoaded(false);
        setSubscribedVendors(null);

        const fetchSubscribedVendors = async() => {
            setSubscribedVendorsLoaded(false);
            const selectedVendorsResponse = await vendorsService.getVendors();
            let vendorData = [];
            if (selectedVendorsResponse) {
                vendorData = selectedVendorsResponse.map((vendor) => {
                   return {
                        label: vendor.vendorGroupName.toUpperCase(),
                        id: vendor.vendorGroupNumber
                   }
                })
            }

            setSubscribedVendors(vendorData);
            setSubscribedVendorsLoaded(true);
        };

        fetchSubscribedVendors();

    }, [vendorGroupNumber, formSubmitted])

    return { subscribedVendors, subscribedVendorsLoaded }
}