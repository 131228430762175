import { MenuItem } from "@mui/material"


export const mapSavedFiltersToMenuItems = (savedFilters) => {
    if (!savedFilters) {
        return (
            <p className="opacity-enabled">No filters available</p>
        )
    }

    return savedFilters.map((filters) => {
        return (
            <MenuItem 
                key={filters.NAME} 
                value={filters.NAME}
            >
                {filters.NAME}
            </MenuItem>
        )
    })
}


export const validateInputs = (str, obj, setConfig, operation, source) => {
    if (operation === "insert") {
        if (!str) {
            setConfig({variation: "error", msg: "Filter name required"});
            return false
        }
        if (source === "customer-data"){  
            if (!obj || Object.keys(obj).length === 0) {
                setConfig({variation: "error", msg: "Please select at least 1 filter to save"});
                return false
            }
        }

    } else if (operation === "delete") {
        if (!str) {
            setConfig({variation: "error", msg: "No filter selected to delete"});
            return false
        }
    }

    return true
}


export const checkStatus = (response, setConfig, operation) => {
    if (!response) {
        setConfig({variation: "error", msg: "Failed to perform operation, please contact an administrator"});
        return
    }

    switch (operation) {
        case "insert":
            switch (response.status) {
                case "success":
                    setConfig({variation: "success", msg: "Filters successfully saved!"});
                    break;
                case "failure":
                    setConfig({variation: "error", msg: "Please ensure saved filters have unique names"});
                    break;
                default:
                    setConfig({variation: "error", msg: "Failed to save filters, please contact an administrator"});
            }
            break;
        case "delete":
            switch (response.status) {
                case "success":
                    setConfig({variation: "success", msg: "Selected filter successfully deleted!"});
                    break;
                case "failure":
                default:
                    setConfig({variation: "error", msg: "Failed to delete filters, please contact an administrator"});
            }
            break;
        default:
            setConfig({variation: "error", msg: "Failed to perform specified operation, please contact an administrator"});
    }
}